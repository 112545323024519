import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
// import logo from "assets/img/reactlogo.png";
import logo from "assets/img/dcypher_logo_1_320x320.png";

// import { ScreenRoutesContextProvider } from "Context/screenAccess-Provider_3"

let ps;


/**  Need to setup global context 'auth' to store all login detail including adminWriteAllowed status 
 * it should be 'provided' to all components ie. 'Auth.Provider' to wrap all components in the app
 * Also auth context 'consumed' here
 * so only if auth.adminWriteAllowed==true should routes with adminWriteAllowed =true be allowed
 */

// For now just set a fake flag to try out

// let auth = {}
// auth.adminWriteAllowed = true




// Create a list of all possible Routes to switch into
const switchRoutes = (

  <Switch>
    {
      
      routes.map((prop, key) => {


        // if (auth.adminWriteAllowed) {

          if (prop.layout === "/admin") {

            console.log("  prop.adminWriteAllowed =", prop.adminWriteAllowed)
            console.log(`**************  ${key}`)

            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            )
          }
        // }


        // auth.adminWriteAllowed must be false

        // else if (!prop.adminWriteAllowed) {
        //   if (prop.layout === "/admin") {

        //     console.log("**** no admin write access")
        //     console.log("  prop.adminWriteAllowed =", prop.adminWriteAllowed)
        //     console.log(`**************  ${key}`)

        //     return (
        //       <Route
        //         path={prop.layout + prop.path}
        //         component={prop.component}
        //         key={key}
        //       />
        //     )
        //   }
        // }
        
        
      return null;
      })
    
    }
    
    <Redirect from="/admin" to="/admin/dashboard" />

  </Switch>


);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown hide");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (

    <div className={classes.wrapper}>

      <Sidebar
        routes={routes}
        logoText={"DCYPHER"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>

    </div>
  );
}
