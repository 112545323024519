import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//import TableDatabase from "../../views/TableDbComp/Table-Database";
import TableDatabaseActivePumpStatus from "../../views/TableDbComp/TableDatabaseActivePumpStatus";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const ActivePumpCartridgeStatusModal = (props) => {
  // DONT NEED ALL THESE PROPS !! -- JUSt FIGURING out how to get firebase node name
  const {
    theDbRef,
    theTitle,
    titleIs,
    specificTitleInfo,
    detailCard,
    rowData,
    detailCardIndex,
  } = props;


  console.log(detailCard);
  console.log("detailCardIndex ->");
  console.log(detailCardIndex);
  console.log("rowData.tableData.id  --> ", rowData.tableData.id);
  console.log("rowData  --> ", rowData);
  console.log("rowData[`${detailCard.field}`]  -->  ");
  console.table(rowData[`${detailCard.field}`]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        className={{ margin: "50px" }}
        color="default"
        variant="contained"
        onClick={handleClickOpen}
        key={'Active Pump Cartridge Status'}
      >
        {/* {theTitle} */}
        {'Active Pump Cartridge Status'}
      </Button>
      <Dialog
        fullWidth={"xl"}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          {/* <DialogContentText>
        `${theTitle}   ${specificTitleInfo}`
           </DialogContentText> */}
          <TableDatabaseActivePumpStatus
            theDbRef={theDbRef}
            theTitle={`${theTitle}   ${specificTitleInfo}`}
            // specificTitleInfo={specificTitleInfo}
            // detailCardIndex={detailCardIndex}
            // detailCard={detailCard}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivePumpCartridgeStatusModal;
