import React, { Component, useEffect, useState } from "react";

import { makeNewAlchs } from "../../databaseConfig/createFakeAlchemists";
import { makeNewAlchsBlankTemplate } from "../../databaseConfig/createFakeAlchemistsBlankTemplate";
import { alchGenLogBlankTemplate } from "../../databaseConfig/createFakeAlchemistGeneralLogBlankTemplate";
import { makeFakeActiveCartList } from "../../databaseConfig/createFakeAlchemistsInstalledList";

import TableDatabase from "../../views/TableDbComp/Table-Database";

//makeNewAlchs(12);

//makeNewAlchsBlankTemplate(1);

//alchGenLogBlankTemplate();

const AlchemistList = () => {
  return (
    <div>
      <TableDatabase theDbRef="alchemists/records" theTitle="" />;
    </div>
  );
};

export default AlchemistList;
