import React, { Component } from "react";
// core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// database
import firebase from "../../firebase";
// import Button from "@material-ui/core/Button";

import LogModal from "../../components/DialogModal/LogModal";
import TablePagination from "@material-ui/core/TablePagination";

import { CsvBuilder } from 'filefy'

/** 
props passed to this component:  
theDbRef  --> eg 'alchemists', 'cartridges/records' 
theTitle  --> e.g. 'Alchemist Table', 'Cartridge Table' OR if non set to ""
**/

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabaseCountBetweenDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      theStartDate: new Date(),
      theFinishDate: new Date()
    };
  }



  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }


  async componentMountandUpdate() {
    this.setState({ loaded: false });

    let theColumnArray = [];
    let newList = [];
    let entriesArray;


    // if (this.props.theDbRef !== "cartridges/records" && this.props.theDbRef !== "productsMade") {
   if (this.props.theDbRef === "productsMade") {

      console.log("******* PRODUCTS MADE SCREEN ******* ")
      console.log("this.props.theStartDate ", this.props.theStartDate)
      console.log("this.props.theFinishDate ", this.props.theFinishDate)

        this.setState({theStartDate: this.props.theStartDate})
        this.setState({theFinishDate: this.props.theFinishDate})


      // Pulling in the whole dB then manipulate.
      // In future should be able to filter directly via Firebase Realtime methods

      await firebase
        .database()
        .ref("batches/records")
        .once("value", (snapshot) => {
          entriesArray = Object.entries(snapshot.val());
        });

      
      console.log("CREATE PRODUCTS MADE ENTRIES ARRAY")
      console.log("Read array and remove ones that arent within the start and finish dates")
      
      let allDevicesProductsMadeArray = []

     


      entriesArray.forEach((entry) => {

        let theCreatedDate = entry[1].created

        if (theCreatedDate >= this.props.theStartDate && theCreatedDate <= this.props.theFinishDate) {

          allDevicesProductsMadeArray.push(entry[1].alchemist_device_id)
          // see if its already in the array, if not add it
          // let arrByID = entriesArray.filter(filterIfDeviceIncluded)
          }
        }
      )


      let setOfDevicesInRange = new Set();

      allDevicesProductsMadeArray.map(item => setOfDevicesInRange.add(item));
      console.log("setOfDevicesInRange  ==> ", setOfDevicesInRange)
      console.log("setOfDevicesInRange.size  ==> ", setOfDevicesInRange.size)

      // let productsMadeDeviceCountOutputArray =[["Machine Id", "number of Bottles Made"]]

      let productsMadeDeviceCountOutputArray = []
      
      setOfDevicesInRange.forEach((Machine_ID, index) => {
        let justTheDevices = allDevicesProductsMadeArray.filter(theDevice => theDevice === Machine_ID)
        let Number_Of_Products_Made = justTheDevices.length

        // Save in an output array 
        //productsMadeDeviceCountOutputArray.push({ "machine Id": Machine_ID, "Number Of Bottles Made": Number_Of_Products_Made })
        productsMadeDeviceCountOutputArray.push(["test", { Machine_ID, Number_Of_Products_Made }])
        
      })



      console.log("**** OUTPUT ARRAY ==> ", productsMadeDeviceCountOutputArray)

      console.table(productsMadeDeviceCountOutputArray)

      let entriesArrayCopy = [...entriesArray]

      entriesArray = [...productsMadeDeviceCountOutputArray]

      console.log("**  entriesArrayCopy ==>  ");
      console.table(entriesArrayCopy);

    }


    // first row = [0], value [1]  gives the column heading:data objects for the first row.
    //Then find the keys for this to get the list of headings


    let lastEntry = entriesArray.length - 1;
    let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);

    const buildColObject = (theArrayOfHeadings) => {
      let anArrayForColumns = [];

      theArrayOfHeadings.forEach((theHeading, i) => {
        let thisCol = {
          title: theHeading,
          field: camelize(theHeading),
          searchable: true,
          hidden:
            // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
            // /log|Log/.test(theHeading) === false
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // remember--> isAnObject === true if an object OR an array!
          isAnObject:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
          isALog: /log|Log/.test(theHeading) ? true : false,
          type: /date|Date/.test(theHeading)
            ? "date"
            : /timestamp|Timestamp/.test(theHeading)
              ? "datetime"
              : /time|Time|created/.test(theHeading)
                ? "datetime"
                : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                  ? "numeric"
                  : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                    ? "string"
                    : typeof entriesArray[lastEntry][1][theHeading],
        };

        if (thisCol.isAnObject) {
          thisCol.editComponent = (props) => (
            <textarea
              type="text"
              multiline={true}
              rows={10}
              value={props.value}
              onChange={(e) => {
                if (JSON.parse(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          );
        }

        anArrayForColumns = [...anArrayForColumns, thisCol];
      });

      // tag ons
      anArrayForColumns = [
        {
          title: "Selected",
          field: "selected",
          type: "boolean",
          hidden: false,
          isAnObject: false,
          searchable: true,
        },

        ...anArrayForColumns,

        // {
        //   title: "Unique Db Id",
        //   field: "uniqueDbId",
        //   type: "string",
        //   hidden: true,
        //   isAnObject: false,
        //   searchable: true,
        // },
        {
          //   title: "group",
          //   field: "group",
          //   // defaultGroupOrder: 0,
          //   defaultGroupSort: "asc",
          //   hidden: true,
          //   isAnObject: false,
          // searchable: true,
        },
      ];

      return anArrayForColumns;
    };

    theColumnArray = buildColObject(arrayOfColHeadings);

    let newRowData = [];

    entriesArray.forEach((childEntry, count) => {
      let parentID = childEntry[0];

      let dataObjectForThisRow = childEntry[1];

      theColumnArray.forEach((heading) => {
        if (heading.title === "Unique Db Id") {
          newRowData.push(parentID);
        } else if (heading.title === "Selected") {
          newRowData.push(false);
        } else if (!heading.hidden) {
          newRowData.push(dataObjectForThisRow[heading.title]);
        } else if (heading.isAnObject) {
          newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
        }
        // else newRowData.push(dataObjectForThisRow[heading.title]);
      });

      newList = [...newList, newRowData];
      newRowData = []; // clear
    });

    let curState = this.state;
    curState.colArray = theColumnArray;
    curState.tableData = newList;
    curState.loaded = true;
    this.setState(curState);
  }



  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }

  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb || this.props.theStartDate !== prevState.theStartDate || this.props.theFinishDate !== prevState.theFinishDate){
      this.componentMountandUpdate()
    }
  }



  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {
      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };

    const renderTheDetails = (rowData) => {
      /* NOTE  -->
         rowData.tableData.id   -->  gives the row index  
         can use a field to access e.g. 'customerId --->   rowData.customerId   = "C12234"
      */

      const paperStyles = {
        padding: "2vh",
        textAlign: "center",
        color: "red",
        backgroundColor: "blue",
        // width: "30%",
      };

      const renderDetailsCardOfAnObject = (theTitle, elementObject, index) => {
        console.log("theTitle  --> ", theTitle);
        console.log("elementObject  --> ", elementObject);
        console.log("index  --> ", index);
        console.log("=================================");
        return (
          <div>
            <Card key={`${theTitle}__${index}`}>
              <Paper className={paperStyles.paper}>
                {elementObject === null ? null : (
                  <RenderSingleObject elementObject={elementObject} />
                )}
              </Paper>
            </Card>
          </div>
        );
      };

      const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
        return theCardArrayRow.map((element, index) => {
          return (
            <div
              key={`row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`}
            >
              {element !== null ? (
                <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
              ) : null}
              {typeof element === "object" && typeof element !== "isALog"
                ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
                // They need to be rendered differently as map() only works for an array
                renderDetailsCardOfAnObject(theTitle, element, index)
                : element}
            </div>
          );
        });
      };

      const renderTheCardDetails = (detailCard, detailCardIndex) => {
        return (
          <div>
            <Card
              key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
            >
              <CardHeader color="rose">
                <h5>{this.state.colArray[detailCardIndex].title}</h5>
              </CardHeader>
              <CardBody>
                <Paper className={paperStyles.paper}>
                  {console.log(
                    "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
                  )}
                  {Array.isArray(
                    JSON.parse(
                      rowData[this.state.colArray[detailCardIndex].field]
                    )
                  ) === true
                    ? outputCardFromArray(
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex,
                      this.state.colArray[detailCardIndex].title
                    )
                    : renderDetailsCardOfAnObject(
                      this.state.colArray[detailCardIndex].title,
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex
                    )}
                </Paper>
              </CardBody>
            </Card>
          </div>
        );
      };

      const getSpecicTitle = (rowData) => {
        let specificTitleInfo = "";

        return specificTitleInfo;
      };

      return (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            color: "black",
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <div>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              p={1}
              justifyContent="space-around"
            >
              {this.state.colArray.map((detailCard, detailCardIndex) => {
                if (detailCard.isAnObject && detailCard.isALog === false) {
                  {
                    return renderTheCardDetails(detailCard, detailCardIndex);
                  }
                } else if (detailCard.isALog === true) {
                  return (
                    <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                    >
                      <LogModal
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                    </div>
                  );
                }
              })}
            </Box>
          </div>
        </div>
      );
    };

    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },

      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
      
    ];




    const renderTheTable = () => {
      return (
        <div>
          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 50, 500]}
                />
              ),
            }}
            options={{
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              // selection: true,
              exportButton: false,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              // exportCsv: (columns, data) => {
              //   alert("Own export" + data.length + " rows");
              // },
            }}
            // onSelectionChange={(rows) =>
            //   console.log("You selected -->  rows ", rows)
            // }
            actions={theActionsArray}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabaseCountBetweenDates;
