import firebase from "firebase";

var Hashids = require("hashids").default;

// import * as admin from "firebase-admin";

//const serviceAccount = require(process.env.GOOGLE_APPLICATION_CREDENTIALS);

// const serviceAccount = require("./serviceAccountKey.json");

// console.log("serviceAccount --> ", serviceAccount);

// Firebase configuration
const firebaseConfig = {
  // apiKey: process.env.API_KEY,
  apiKey: "AIzaSyDYFQ6TjrPjhkChwvgRkrHrsGkgGKHqFMM",

  //authDomain: process.env.AUTH_DOMAIN,
  authDomain: "the-alchemist-c47c5.firebaseapp.com",

  // REAL database for project
  databaseURL: "https://the-alchemist-c47c5.firebaseio.com/",

  // FAKE database to the default for project
  // databaseURL: "https://the-alchemist-c47c5-3bea3.firebaseio.com",

  // FAKE database for testing security rules 
  //databaseURL: "https://the-alchemist-c47c5-af588.firebaseio.com/",

  //projectId: process.env.PROJECT_ID,
  projectId: "the-alchemist-c47c5",

  //storageBucket: process.env.STORAGE_BUCKET,
  storageBucket: "the-alchemist-c47c5.appspot.com",
};

// firebase-adminsdk-p44uq@the-alchemist-c47c5.iam.gserviceaccount.com

//export GOOGLE_APPLICATION_CREDENTIALS="~/Downloads/service-account-file.json"

// Initialise
firebase.initializeApp(firebaseConfig);

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//   //credential: admin.credential.refreshToken(refreshToken),
//   //   credential: admin.credential.applicationDefault(),
//   databaseURL: "https://the-alchemist-c47c5.firebaseio.com",
// });

export default firebase;

// Create new account using email / password
export async function firebaseCreateAdminAccount(
  name,
  email,
  password,
  error_callback
) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((authData) => {
      // Success, set display name
      if (authData.user) {
        authData.user.updateProfile({
          displayName: name,
        });
      }

      // Add user to Firebase users nodes
      console.log("NEW USER TO ADD TO DB");
      console.log(" authData.user.uid --> ", authData.user.uid);
      console.log(" authData.user.email --> ", authData.user.email);
      console.log(" name --> ", name);

      firebase
        .database()
        .ref(`users/${authData.user.uid}`)
        .update({
          uid: authData.user.uid,
          name,
          email: authData.user.email,
          admin: true,
          created: new Date().getTime(),
        });
    })
    .catch((error) => {
      // Failed, call user-provided callback
      error_callback(error);
    });
}

// Create New user BUT - unlike createUserWithEmailAndPassword-
// don't switch current to that user, remain as current user
// this is for creating new user accounts inside dashboard
export async function firebaseCreateNewUserButDontChangeCurrentUserLogedIn(
  name,
  email,
  password,
  retailId,
  adminStatus,
  error_callback
) {
  console.log(
    `firebase.js --> firebaseCreateNewUserButDontChangeCurrentUserLogedIn   ====>\nname  ${name}       \nemail  ${email}       \npassword  ${password}   \nretailId ${retailId} \nadminStatus ${adminStatus}`
  );

  const createAdminUserAsync = firebase
    .functions()
    .httpsCallable("createAdminUserAsync");
  createAdminUserAsync({
    name: name,
    email: email,
    password: password,
    retailId: retailId,
    adminStatus: adminStatus,
  })
    .then((newUserData) => {
      const theUid = newUserData.data.data.uid;
      console.log(newUserData.data.message);
      console.log("uid -->", newUserData.data.data.uid);
    })
    .catch((error) => {
      // Failed, call user-provided callback
      error_callback(error);
    });
}

// Login using email / password
export async function firebaseLogin(email, password, error_callback) {
  // Check parameters
  if (!email || !password) {
    error_callback({ code: -1, message: "No email or password specified" });
    return;
  }

  // login
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(error_callback);
}

// Register handler to be called on authentication state change
export function firebaseRegisterAuthCallback(callback) {
  // Listen for authentication state to change.
  // Returns the unsubscribe function.
  return firebase.auth().onAuthStateChanged((user) => {
    if (user != null) {
      console.log("We are authenticated now: " + JSON.stringify(user));
      callback(user);
    }
  });
}





/**************    TEMPORARY TO TEST FIREBASE SECURITY  ****************************
 * 
 * 
 */

// Creates a new, incremental record. Return the new number.
export function firebaseBatchesCreateID(callback, error_callback, callback_param) {
  // increment the counter
  firebase
    .database()
    .ref(`batches/counter`)
    .transaction(
      function (currentValue) {
        return (currentValue || 0) + 1;
      },
      function (err, committed, ss) {  // 'ss' short for snapshot ie the value of the counter in this case
        console.log("COMMITTED: " + committed);
        if (err) {
          // Failed, call error callback
          if (error_callback) {
            error_callback(err, callback_param);
          }
        } else if (committed) {
          // If counter update succeeds then tell caller
          // thus ensuring its an atomic modification
          if (callback) {
            callback(createHash(ss.val()), callback_param);
          }
        }
      }
    );
}

// Creates new incremental record
export function firebaseBatchesAddRecord(
  alchemist_device_id,
  made_in,
  cartridge_info,
  choices,
  batch_id,
  expiry,
  volumes
) {
  // Add batch to Firebase batches nodes
  firebase.database().ref(`batches/records`).push().set({
    alchemist_device_id,
    made_in,
    cartridge_info,
    choices,
    batch_id,
    expiry,
    volumes,
    created: new Date().getTime(),
  });
}

// Create a unique hash from the batch counter
function createHash(counter) {
  // Pad to length 11, use only capitals and digits
  var hashids = new Hashids("", 11, "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890");
  return hashids.encode(counter);
}

