import React, { Component } from "react";
// core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
// import Button from "components/CustomButtons/Button.js";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";


// database
import firebase from "../../firebase";

import LogModal from "../../components/DialogModal/LogModal";
import EditableDetailObjectModal from "../../components/DialogModal/EditableDetailObjectModal";
import TablePagination from "@material-ui/core/TablePagination";

import { CsvBuilder } from 'filefy'

/** 
props passed to this component:  
theDbRef  --> eg  'alchemist/records/uniqueDbId' 
theTitle  --> e.g. Address
**/

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false
    };
  }

  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }



  async componentMountandUpdate() {
    this.setState({ loaded: false });

    let theColumnArray = [];
    let newList = [];
    let entriesArray;

    let refTemplateDbIs = "";

    // Figure out what template to get
    let refString = this.props.theDbRef.split("/");
    


      await firebase
        .database()
        .ref(this.props.theDbRef)
        .once("value", (snapshot) => {
          entriesArray = Object.entries(snapshot.val());
        });
    


    /************************************************************************************************
     *  TRANSFORM entiresArray 
     * 
     * Example for Address object
     * 
     * ['City', 'Warrington']
     * ['Country', 'England']
     * ['Post Code', 'AAA111']
     * 
     * 
     * 
     * becomes
     * 
     * 
     *  [[
     *    "-UniquDbId/Address",
     *       {'city':'Warrington', 'Country':'England', 'Post Code':'AAA111'}
     *   ]]
     * 
     * 
     * */

    
    /*** NOTE: Since its an detail object it should only be a single row of heading/value pairs*/
    let detailedObjectArray = [[this.props.theDbRef, {}]]

    
    entriesArray.forEach(entry => {
      detailedObjectArray[0][1][entry[0]] = entry[1]
    })
    


    entriesArray = [...detailedObjectArray ]
    
    let lastEntry = entriesArray.length - 1;

    let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);
        
    
    console.log("==================== DID MOUNT =========")
    console.log("TableDatabaseForEditableDetailedObjects -->  entriesArray ----> ")
    console.table(entriesArray)
    console.log("arrayOfColHeadings ----> ")
    console.table(arrayOfColHeadings)
   console.log("====================")



    const buildColObject = (theArrayOfHeadings) => {
      let anArrayForColumns = [];

      theArrayOfHeadings.forEach((theHeading, i) => {
        let thisCol = {
          title: theHeading,
          field: camelize(theHeading),
          searchable: true,
          hidden:
            // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
            // /log|Log/.test(theHeading) === false
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // remember--> isAnObject === true if an object OR an array!
          isAnObject:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
          isALog: /log|Log/.test(theHeading) ? true : false,
          type: /date|Date/.test(theHeading)
            ? "date"
            : /timestamp|Timestamp/.test(theHeading)
                ? "datetime"
                : /^(?!.*timezone).*time|^(?!.*Timezone).*Time|created/.test(theHeading)
                    ? "datetime"
                    // : /timezone|Timezone/.test(theHeading)
                    //     ? "string"
                        : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                            ? "numeric"
                            : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                                ? "string"
                                : typeof entriesArray[lastEntry][1][theHeading],
        };




        if (thisCol.isAnObject) {
          thisCol.editComponent = (props) => (
            <textarea
              type="text"
              multiline={true}
              rows={10}
              value={props.value}
              onChange={(e) => {
                if (JSON.parse(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          );
        }

        anArrayForColumns = [...anArrayForColumns, thisCol];
      });




      // tag ons
      anArrayForColumns = [
        {
          title: "Selected",
          field: "selected",
          type: "boolean",
          hidden: false,
          isAnObject: false,
          searchable: true,
        },

        ...anArrayForColumns,

        {
          title: "Unique Db Id",
          field: "uniqueDbId",
          type: "string",
          hidden: true,
          isAnObject: false,
          searchable: true,
        },
        {
          //   title: "group",
          //   field: "group",
          //   // defaultGroupOrder: 0,
          //   defaultGroupSort: "asc",
          //   hidden: true,
          //   isAnObject: false,
          // searchable: true,
        },
      ];

      return anArrayForColumns;
    };



    theColumnArray = buildColObject(arrayOfColHeadings);

    let newRowData = [];

    entriesArray.forEach((childEntry, count) => {
      let parentID = childEntry[0];

      let dataObjectForThisRow = childEntry[1];

      theColumnArray.forEach((heading) => {
        if (heading.title === "Unique Db Id") {
          newRowData.push(parentID);
        } else if (heading.title === "Selected") {
          newRowData.push(false);
        } else if (!heading.hidden) {
          newRowData.push(dataObjectForThisRow[heading.title]);
        } else if (heading.isAnObject) {
          newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
        }
        // else newRowData.push(dataObjectForThisRow[heading.title]);
      });

      newList = [...newList, newRowData];
      newRowData = []; // clear
    });

    let curState = this.state;
    curState.colArray = theColumnArray;
    curState.tableData = newList;
    curState.loaded = true;
    this.setState(curState);
  }


  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }


  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }


  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {

      const listStyle = {
        padding:"1ch 5vw"
      }


      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li style={listStyle}>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };

    const renderTheDetails = (rowData) => {
      /* NOTE  -->
         rowData.tableData.id   -->  gives the row index  
         can use a field to access e.g. 'customerId --->   rowData.customerId   = "C12234"
      */

      const paperStyles = {
        padding: "2vh",
        textAlign: "center",
        color: "red",
        backgroundColor: "blue",
        // width: "30%",
      };



      const EditDetailObjButt = (props) => {


        const editableDetailsObjectsSet = new Set()
        editableDetailsObjectsSet.add("Address")

        console.log("  EditDetailObjButt props == ", props.title)
        console.log("editableDetailsObjectsSet.has(title) --> ", editableDetailsObjectsSet.has(props.title))

        return ( editableDetailsObjectsSet.has(props.title) ?
          <div
          >
            <EditableDetailObjectModal
          theTitle={props.title}
          theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${props.title}`}
        />

          </div>
          : null
        )
      }


      const renderDetailsCardOfAnObject = (theTitle, elementObject, index) => {

        console.log("theTitle  --> ", theTitle);
        console.log("elementObject  --> ", elementObject);
        console.log("index  --> ", index);
        console.log("=================================");

        return (
          <div>
            <Card key={`${theTitle}__${index}`}>
    
              {/* <EditDetailObjButt title={theTitle} /> */}

              <Paper className={paperStyles.paper}>
                {elementObject === null ? null : (
                  <RenderSingleObject elementObject={elementObject} />
                )}
              </Paper>
            </Card>
          </div>
        );
      };



      const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
        return theCardArrayRow.map((element, index) => {
          return (
            <div
              key={`row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`}
            >
              {element !== null ? (
                <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
              ) : null}
              {typeof element === "object" && typeof element !== "isALog"
                ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
                // They need to be rendered differently as map() only works for an array
                renderDetailsCardOfAnObject(theTitle, element, index)
                : element}
            </div>
          );
        });
      };



      const renderTheCardDetails = (detailCard, detailCardIndex) => {
        return (
          <div>
            <Card
              key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
            >
              <CardHeader color="rose">
                <h5>{this.state.colArray[detailCardIndex].title}</h5>
                <EditDetailObjButt title={this.state.colArray[detailCardIndex].title} />
              </CardHeader>
              <CardBody>
                <Paper className={paperStyles.paper}>
                  {console.log(
                    "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
                  )}
                  {Array.isArray(
                    JSON.parse(
                      rowData[this.state.colArray[detailCardIndex].field]
                    )
                  ) === true
                    ? outputCardFromArray(
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex,
                      this.state.colArray[detailCardIndex].title
                    )
                    : renderDetailsCardOfAnObject(
                      this.state.colArray[detailCardIndex].title,
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex
                    )}
                </Paper>
              </CardBody>
            </Card>
          </div>
        );
      };



      const getSpecicTitle = (rowData) => {
        let specificTitleInfo = "";

        if (/cartridges/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Device:${rowData.cartridgeBatchId}`;
        } else if (/alchemists/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Device:${rowData.deviceId} ------ Location:${rowData.locationId}`;
        } else if (/product units/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Unique Id: ${rowData.uniqueDbId}`;
          // specificTitleInfo = `Unique Id --> `;
        } else if (/General Cartridge Flags Log/.test(this.props.theDbRef)) {
          console.log("General Cartridge Flags Log");
        } else if (/Cartridge Stock Tally Log/.test(this.props.theDbRef)) {
          console.log("Cartridge Stock Tally Log");
        }

        return specificTitleInfo;
      };



      return (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            color: "black",
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <div>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              p={1}
              justifyContent="space-around"
            >
              {this.state.colArray.map((detailCard, detailCardIndex) => {
                if (detailCard.isAnObject && detailCard.isALog === false) {
                  {
                    return renderTheCardDetails(detailCard, detailCardIndex);
                  }
                } else if (detailCard.isALog === true) {
                  return (
                    <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                    >
                      <LogModal
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                    </div>
                  );
                }
              })}
            </Box>
          </div>
        </div>
      );
    };




    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");
          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)
          console.log("*****************************\n");
          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },
      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
    ];



    const renderTheTable = () => {

      const getMainBranchRef = () => {
        // this.props.theDbRef !== "cartridges/records"
        let mainBranch = this.props.theDbRef.split('/')
        let branchRef = `${mainBranch[0]}/${mainBranch[1]}`
        return branchRef
      }

      return (
        <div>

          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            detailPanel={[
              {
                tooltip: "Show Extra Details",
                render: renderTheDetails,
              },
            ]}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 50, 500]}
                />
              ),
            }}
            options={{
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              // selection: true,
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

            }}


            actions={theActionsArray}
            editable={{
              onRowUpdate:
                // this.props.theDbRef !== "cartridges/records"
                // getMainBranchRef() !== "cartridges/records"
                  // ? (newData, oldData) =>
                  (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log("NORMAL SCREEEN UPDATE -- WORKS!!");
                      console.log("oldData ===>> ", oldData);
                      console.log("newData ===>> ", newData);
                      console.log("id ==  ", oldData.tableData.id);
                      console.log("this.state.tableData == > >");
                      console.table(this.state.tableData);


                      let newRowBuild = {};
                      let theData = Object.entries(newData);



                      theData.map((element, theIndex) => {
                        console.log("element --> ", element);
                        console.log("theIndex  --> ", theIndex);

                        let dataIs = element[1];
                        let titleName = this.state.colArray[theIndex].title;

                        if (
                          /Date/.test(titleName) ||
                          /date/.test(titleName)
                        ) {
                          //   need to check if date/time to parse to a timestamp
                          console.log("date --> Date.parse(dataIs)");
                          console.log(Date.parse(dataIs));
                          dataIs = Date.parse(dataIs);
                        } else if (
                          /^(?!.*timezone).*time/.test(titleName) ||
                          /^(?!.*Timezone).*Time/.test(titleName) ||
                          /timestamp/.test(titleName) ||
                          /Timestamp/.test(titleName)
                        ) {
                          //   need to check if time to parse to a timestamp
                          //dataIs = new Date(dataIs).getTime();
                          dataIs = Date.parse(dataIs);
                        } else if (
                          this.state.colArray[theIndex].type === "numeric"
                        ) {
                          dataIs = parseFloat(dataIs);
                        } else if (/\{/.test(dataIs)) {
                          // check if its an object
                          dataIs = JSON.parse(dataIs);
                        }

                        newRowBuild[titleName] = dataIs;
                      });

                      console.log("  newRowBuild ==>");
                      console.table(newRowBuild);


                      // ******
                      // use the newRowBuild Array e.g.
                      //
                      // {
                      //  "Selected": false,
                      //  "City": "Warrington",
                      //  "Country": "England",
                      //  "Post Code": "QQQ111",
                      //  "Unique Db Id": "alchemists/records/-MAaCe_xuZsow3jliZkL/Address"
                      // }
                      //
                      // reassign 'Unique Db Id' to get very near the correct database ref.
                      // then remove 'Unique Db Id'and 'Selected' . Call this object 'newUpdated'
                      // then loop over each of the object keys to get the final db ref and its value
                      // ******* 

                      let closeToThisRef = newRowBuild['Unique Db Id']


                      const oldDataWithUniqueDbIdAndGroupColDeleted = (
                        theOldDataObject
                      ) => {
                        let newDataObj = { ...theOldDataObject };
                        delete newDataObj.tableData;
                        delete newDataObj["Unique Db Id"];
                        delete newDataObj["Selected"];
                        delete newDataObj.group;
                        return newDataObj;
                      };

                      let newUpdated = oldDataWithUniqueDbIdAndGroupColDeleted(
                        newRowBuild
                      );


                        // loop over the newUpdated 
                      for (const heading in newUpdated ) {

                        console.log("==== send to Fiirebase ====")
                        console.log("ref ==>  ", closeToThisRef)
                        console.log("child ==> ", heading)
                        console.log("and set to ==> ", newUpdated[heading])
                        console.log("============================")


                      firebase
                        .database()
                        .ref(closeToThisRef)
                        .child(heading)
                        .set(newRowBuild[heading], (error) => {
                          if (error) {
                            reject(
                              alert(
                                "Sorry, had a problem updating to the database. Check if online"
                              )
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });

                      }


                    })
                  
            }}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabase;
