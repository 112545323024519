import React, { Component } from "react";
// core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
// import Button from "components/CustomButtons/Button.js";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

// database
import firebase from "../../firebase";

import LogModal from "../../components/DialogModal/LogModal";
import TablePagination from "@material-ui/core/TablePagination";

import { CsvBuilder } from 'filefy'

/** 
props passed to this component:  
theDbRef  --> eg 'alchemists', 'cartridges/records' 
theTitle  --> e.g. 'Alchemist Table', 'Cartridge Table' OR if non set to ""
**/

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      blankTemplate: {},
      deleteRowsAllowed: false,
      addGeneralLogs: false,
      theHiddenColumsSet: new Set(),
      theStartDate: new Date(),
      theFinishDate: new Date(),
    };
  }

  // getNewSignInEmailPassVal(theValues) {
  //   console.log("getNewSignInEmailPassVal --> ", theValues);
    
  // }

  // setTheSubmitt(subVal) {
  //   console.log("subVal ---> ", subVal);
  //   console.log("state --> ", this.state);

  //   //this.setState((prevState) => ({ submittValue: !prevState.submittValue }));
  // }


  async componentMountandUpdate() {
  this.setState({ loaded: false });

  let theColumnArray = [];
  let newList = [];
  let entriesArray;
  let hiddenColumnSet = new Set()
  let stringSet = new Set()
  let objectSet = new Set()


  /************  PRODUCTS LIST SCREEN  ******/
  /******************************************/

    // console.clear()

    console.log("******* PRODUCTS LIST ******* ");


    this.setState()

    /**  Capture all the data from Firebase  */
    
    this.setState({ theStartDate: this.props.theStartDate });
    this.setState({ theFinishDate: this.props.theFinishDate });
  
    console.log("theStartDate is ", this.state.theStartDate.getTime())
    console.log("theFinishDate is ", this.state.theFinishDate.getTime())

    await firebase
      .database()
      .ref("batches/records")
      .orderByChild("created")
      .startAt(this.props.theStartDate.getTime())
      .endAt(this.props.theFinishDate.getTime())
      .once("value", (snapshot) => {
        entriesArray = Object.entries(snapshot.val());
      });
    
    


    console.log("-------------------------------------------")
    console.log("ENTRIES ARRAY PULLED IN FROM FIREBASE ===>>")
    console.table(entriesArray)
    console.log("-------------------------------------------")
  
    
  let theEntries = []

    for (let entryIndex = 1; entryIndex < entriesArray.length; entryIndex++) {
        theEntries.push(entriesArray[entryIndex])
  }

    
    
  theEntries.reverse() 
  
  entriesArray = [...theEntries]
  
  console.log("**  entriesArray ==>  ")
  console.table(entriesArray);

// **** Get all headings into a set ****/

let headingSet = new Set()


  theEntries.forEach(entry => {
  
  let getAllTheHeadings = Object.keys(entry[1])

  getAllTheHeadings.forEach(heading => {
    headingSet.add(heading)
  });

});
  

  console.log("===== list Of Heading Groups =====")
  console.log(headingSet)

  let arrayOfColHeadings = Array.from(headingSet)

    
    
    

/******************************  start of build column  **********************************/
    
  console.log("array Of Col Headings ----> ")
  console.table(arrayOfColHeadings)

// TODO : Needs to create this array for each row aka each element in the entriesArray
  const buildColObject = (theArrayOfHeadings) => {
    let anArrayForColumns = [];

    theArrayOfHeadings.forEach((theHeading, i) => {
      let thisCol = {
        title: theHeading,
        field: camelize(theHeading),
        searchable: true,
        hidden:
          theHeading === "email"
            ? true
            : theHeading === "name"
              ? true
              : theHeading === "uid"
                ? true
                // : theHeading === "made_in"
                // ? true
                :
                (typeof theEntries[0][1][theHeading]).toString() === "object"
                  ? true
                  : false,
                      isAnObject:
                        (typeof theEntries[0][1][theHeading]).toString() === "object"
                          ? true
                          : false,
                      // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
                      isALog: /log|Log/.test(theHeading) ? true : false,
                      type: /date|Date/.test(theHeading)
                        ? "date"
                        : /timestamp|Timestamp/.test(theHeading)
                          ? "datetime"
                          : /time|Time|created|expiry|expiry_date/.test(theHeading)
                            ? "datetime"
                            : (typeof theEntries[0][1][theHeading]).toString() === "number"
                              ? "numeric"
                              : (typeof theEntries[0][1][theHeading]).toString() === "object"
                                ? "string"
                                : (typeof theEntries[0][1][theHeading]).toString() === "undefined"
                                ? "string"
                                  : typeof theEntries[0][1][theHeading],
           };

      if (thisCol.isAnObject) {
        thisCol.editComponent = (props) => (
          <textarea
            type="text"
            multiline={true}
            rows={10}
            value={props.value}
            onChange={(e) => {
              if (JSON.parse(e.target.value)) {
                props.onChange(e.target.value);
              }
            }}
          />
        );
      }

      anArrayForColumns = [...anArrayForColumns, thisCol];
    });

    // tag ons
    anArrayForColumns = [
      {
        title: "Selected",
        field: "selected",
        type: "boolean",
        hidden: false,
        isAnObject: false,
        searchable: true,
      },

      ...anArrayForColumns,

      {
        title: "Unique Db Id",
        field: "uniqueDbId",
        type: "string",
        hidden: true,
        isAnObject: false,
        searchable: true,
      },
      {
        //   title: "group",
        //   field: "group",
        //   // defaultGroupOrder: 0,
        //   defaultGroupSort: "asc",
        //   hidden: true,
        //   isAnObject: false,
        // searchable: true,
      },
    ];

    return anArrayForColumns;
  };
    
    
    theColumnArray = buildColObject(arrayOfColHeadings);
    

    

  let newRowData = []

    const convertAllStringColumsToObject = () => {
  
      let theList = [...newList]

      console.log("Convert All Strings in Object Columns to Objects")

      theEntries.forEach((childEntryAgain, rowNumberAgain) => { 
      //entriesArray.forEach((childEntryAgain, rowNumberAgain) => { 


        theColumnArray.forEach((heading, i) => { 
          if (this.state.theHiddenColumsSet.has(heading.title)) {
            
                // check is its a string if so convert to object
            console.log("--------------------------------")
            console.log("heading.title  ==> ", heading.title)
            console.log("heading.isAnObject  ==> ", heading.isAnObject)
            console.log("childEntryAgain[1][heading.title]  Content is ==> ", childEntryAgain[1][heading.title])

            if (typeof childEntryAgain[1][heading.title] === 'string') {
              
              heading.isAnObject = true

              let convertObj = {}
              convertObj[heading.title] = childEntryAgain[1][heading.title]
              console.log(`CONVERT to -->`)
              console.log(JSON.stringify(convertObj))

              console.log(`theList[rowNumberAgain][i]  -->`)
              console.log(theList[rowNumberAgain][i])

              theList[rowNumberAgain][i] = JSON.stringify(convertObj)
              console.log("theList is now ==>")
              console.log(theList)
            }

            console.log("--------------------------------\n\n")

            }
        })

      })

      return theList

}



  theEntries.forEach((childEntry, rowNumber) => {
  //entriesArray.forEach((childEntry, rowNumber) => {
    let parentID = childEntry[0];

    let dataObjectForThisRow = childEntry[1];



    theColumnArray.forEach((heading) => {

      // second check if its in stringSet AND objectSet then must also set in hiddenColumnSet
      if (typeof dataObjectForThisRow[heading.title] === "string") {
        stringSet.add(heading.title) 
      } else if (typeof dataObjectForThisRow[heading.title] === "object") {
        objectSet.add(heading.title)
      } 

      if (heading.title === "Unique Db Id") {
        newRowData.push(parentID);
      } else if (heading.title === "Selected") {
        newRowData.push(false);
      } else if (!heading.hidden) {
        newRowData.push(dataObjectForThisRow[heading.title]);
      } else if (heading.isAnObject) {
        newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]))
        hiddenColumnSet.add(heading.title) // first check: if its an object must be hidden
      }
        

    })


    newList = [...newList, newRowData]
    newRowData = [] // clear


    if (theEntries.length - 1 === rowNumber)
    {
              
              let theStringArray = Array.from(stringSet)

              theStringArray.forEach(ele => {
                if (objectSet.has(ele)) {
                  hiddenColumnSet.add(ele)
                }
              })
              
      

            this.setState({ theHiddenColumsSet: hiddenColumnSet })

            console.log("++++++++++++++ the Hidden Column Set  ++++++++++++++++")
            console.log(this.state.theHiddenColumsSet)
            console.log("++++++++++++++++++++++++++++++++++++++++++++++++++")

    }

    
      
  })
    
    
  
  // Can now refresh the colArray so that the colums contained in the hiddenColumnState
  // and ensure that id a string they are converted to an object

    newList = convertAllStringColumsToObject()
    

      // set all hiddenColumnSet to hidden and convert all strings to objects
      
                      for (let rowNumber_ = 0; rowNumber_ < theEntries.length; rowNumber_++) {
                    
                        theColumnArray.forEach((heading_, i) => {
                          
                          let arrayData = theEntries[rowNumber_][1][heading_.title]
                          let arrayTest = Array.isArray(theEntries[rowNumber_][1][heading_.title])

                            if (hiddenColumnSet.has(heading_.title) && arrayTest) {
                            newList[rowNumber_][i] = JSON.stringify(arrayData)
                            heading_.hidden = true
                            heading_.isAnObject = true
                          }

                        })
                        
                      }
    
      console.log("%%%%%%%    newList  AFTER  CHECKING COL HEADINGS %%%%%%%%")
      console.table(newList)
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")


  let curState = this.state
  curState.colArray = theColumnArray
  curState.tableData = newList
  curState.loaded = true
  this.setState(curState)
    


  console.log("================    STATE at END OF new version     ===============")
  console.table(this.state)
  console.log("====================================================")

}


  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
componentDidMount() {
    this.componentMountandUpdate()
  }

  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    // if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      if (
        this.state.updateFirebaseDb !== prevState.updateFirebaseDb ||
        this.props.theStartDate !== prevState.theStartDate ||
        this.props.theFinishDate !== prevState.theFinishDate
      ) {
      this.componentMountandUpdate()
    }
  }

  /*******************   RENDER    ******************/
  /**************************************************/
  render() {

   

    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row, i) => {

      let objectOfFieldData = {}

      this.state.colArray.forEach((col, colNumber) => {


        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {

      const listStyle = {
        padding:"1ch 5vw"
      }

      let count = this.props.count + 1 || 0
      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ?
          element[0] === "expiry_date" ?
          (
              <li style={listStyle} key={this.props.key+indexInner + element[0] + '_1' + count}>
                 {console.log(`>>>>>>>>1  element  ${indexInner+element}   >>>>>>>>>>>`)}
              {element[0]} --{">"} {new Date(element[1]).toDateString()}
            </li>
            )
            : (typeof element[1] === "number" && element[1] % 1 != 0) ?
              (
                <li style={listStyle} key={this.props.key + indexInner + element[0] + '_2' + count}>
                  {console.log(`>>>>>>>>2  element  ${indexInner+element}   >>>>>>>>>>>`)}
                {element[0]} --{">"} {element[1].toFixed(2)}
              </li>
              )
              : (
                <li style={listStyle} key={this.props.key + indexInner + element[0] + '_3' + count}>
                   {console.log(`>>>>>>>>3  element  ${indexInner+element}   >>>>>>>>>>>`)}
                  {element[0]} --{">"} {element[1]}
                </li>
                )
                : (
                <div>
              <h6 key={this.props.key + indexInner + element[0] + '_4' + count} >{element[0]}</h6>
              {console.log(`>>>>>>>>4  element  ${indexInner}   >>>>>>>>>>>`)}
              <RenderSingleObject elementObject={element[1]} key={this.props.key + indexInner + '_5' + count} count={count}/>
                </div>
        )
      )
      return <div>{theList}</div>
    }

    let allKeysUsed = new Set()

    const renderTheDetails = (rowData) => {
      /* NOTE  -->
         rowData.tableData.id   -->  gives the row index  
         can use a field to access e.g. 'customerId --->   rowData.customerId   = "C12234"
      */
      

      const paperStyles = {
        padding: "2vh",
        textAlign: "center",
        color: "red",
        backgroundColor: "blue"
      }


      const renderDetailsCardOfAnObject = (theTitle, elementObject, index, theKey) => {
        
        console.log("theTitle  --> ", theTitle)
        console.log("elementObject  --> ", elementObject)
        console.log("index  --> ", index)
        console.log("theKey --> ", theKey)

        if (allKeysUsed.has(theKey)){ console.error("ALREADY HAVE THIS KEY") }
        allKeysUsed.add(theKey)
        console.log("=================================")
        return (
          <div>
            {console.log(`+++++++  theTitle__index  ${theTitle}__${index}   ++==+++==++`)}
            <Card key={`${theTitle}__${index}`}>
              <Paper className={paperStyles.paper}>
                {(elementObject === null || elementObject === "" || elementObject === undefined) ? null : (
                  <RenderSingleObject elementObject={elementObject} key={`${theKey}_${index}`} />
                )}
              </Paper>
            </Card>
          </div>
        )
      }



      const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
        
        return theCardArrayRow.map((element, index) => {
          const theKey = `row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`
          return (
            <div
              key={theKey}
            >
              {(element !== null) ? (
                <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
              ) : null}
              {typeof element === "object" && typeof element !== "isALog"
                ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
                // They need to be rendered differently as map() only works for an array
                renderDetailsCardOfAnObject(theTitle, element, index, theKey)
                : element}
            </div>
          );
        });
      };



      const renderTheCardDetails = (detailCard, detailCardIndex) => {
        if (rowData[this.state.colArray[detailCardIndex].field] === undefined) {
          /******   undefined object aka dosnt exist */
          const anotherKey = `detail_${rowData.tableData.id}_detail_${detailCardIndex}`
          return (
            <div>
              <Card
                key={anotherKey}
              >
                <CardHeader style={{ backgroundColor: "#888" }}>
                  <h5>{this.state.colArray[detailCardIndex].title}</h5>
                </CardHeader>
                <CardBody>
                  <Paper className={paperStyles.paper}>
                    <div>
                      <h6>Unknown</h6>
                    </div>
                  </Paper>
                </CardBody>
              </Card>
            </div>
          )
        }
        else
        {
          const anotherKey = `detail_${rowData.tableData.id}_detail_${detailCardIndex}`
          return (
            <div>
              <Card
                key={anotherKey}
              >
                <CardHeader color="rose">
                  <h5>{this.state.colArray[detailCardIndex].title}</h5>
                </CardHeader>
                <CardBody>
                  <Paper className={paperStyles.paper}>
                    {console.log(
                      "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
                    )}
                    {Array.isArray(
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      )
                    ) === true
                      ? outputCardFromArray(
                        JSON.parse(
                          rowData[this.state.colArray[detailCardIndex].field]
                        ),
                        detailCardIndex,
                        this.state.colArray[detailCardIndex].title
                      )
                      : renderDetailsCardOfAnObject(
                        this.state.colArray[detailCardIndex].title,
                        JSON.parse(
                          rowData[this.state.colArray[detailCardIndex].field]
                        ),
                        detailCardIndex,
                        anotherKey
                      )}
                  </Paper>
                </CardBody>
              </Card>
            </div>
          )
      }
      };

      const getSpecicTitle = (rowData) => {
        let specificTitleInfo = "";

        if (/cartridges/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Device:${rowData.cartridgeBatchId}`;
        } else if (/alchemists/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Device:${rowData.deviceId} ------ Location:${rowData.locationId}`;
        } else if (/product units/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Unique Id: ${rowData.uniqueDbId}`;
          // specificTitleInfo = `Unique Id --> `;
        } else if (/General Cartridge Flags Log/.test(this.props.theDbRef)) {
          console.log("General Cartridge Flags Log");
        } else if (/Cartridge Stock Tally Log/.test(this.props.theDbRef)) {
          console.log("Cartridge Stock Tally Log");
        }

        return specificTitleInfo;
      };

      return (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            color: "black",
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <div>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              p={1}
              justifyContent="space-around"
            >
              {this.state.colArray.map((detailCard, detailCardIndex) => {
                if (detailCard.isAnObject && detailCard.isALog === false) {
                  {
                    return renderTheCardDetails(detailCard, detailCardIndex);
                  }
                } else if (detailCard.isALog === true) {
                  return (
                    <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                    >
                      <LogModal
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                    </div>
                  );
                }
              })}
            </Box>
          </div>
        </div>
      );
    };

    let theActionsArray = [


      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          

          // return <div>TESTING render onClick</div>;
        },
      },

      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
    ];


    if (this.props.theDbRef !== "users") {
      theActionsArray = [
        ...theActionsArray,
        {
          tooltip: "show -Add General Logs- button",
          icon: () => <NoteAddIcon />,
          isFreeAction: true,
          onClick: (evt, data) => {
            console.log("show -Add General Logs- button");
            this.setState((prevState) => ({
              addGeneralLogs: !prevState.addGeneralLogs,
            }));
          },
        },
      ];
    }

    if (this.state.addGeneralLogs === true && this.props.theDbRef !== "users") {
      theActionsArray = [
        ...theActionsArray,
        {
          tooltip: "Create a General Logs",
          icon: () => <NoteAddIcon />,
          onClick: (evt, data) => {
            console.log("Add a General log");

            const genLog = {
              Flags: "TBD",
              Notes: "This is  where notes go",
              timestamp: 23299943,
            };

            const createGenLog = () => {
              firebase.database().ref(
                this.props.theDbRef
                  .child(data["uniqueDbId"])
                  .child("General Logs")
                  .push(genLog)
              );
            };

            data["generalLogs"] === {}
              ? createGenLog()
              : console.log("already have a general log");
            console.log("this.props.theDbRef", this.props.theDbRef);
            console.log("*** data['uniqueDbId'] ==> ", data["uniqueDbId"]);
            console.log("data.tableData.id  ==>", data.tableData.id);
          },
        },
      ];
    }



    const renderTheTable = () => {
      return (
        <div>
          {/*this.state.showModal ? (
            <LogModal
            // theTitle="Cartridge Log TEST!!"
            // theDbRef="cartridges/"
            />
          ) : null*/}

          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            detailPanel={[
              {
                tooltip: "Show Extra Details",
                render: renderTheDetails,
              },
            ]}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[50, 100]}
                />
              ),
            }}
            options={{
              pageSize:50,
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              //filtering: true,
              search: true,
              // selection: true,
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              // exportCsv: (columns, data) => {
              //   alert("Own export" + data.length + " rows");
              // },
            }}
            // onSelectionChange={(rows) =>
            //   console.log("You selected -->  rows ", rows)
            // }
            actions={theActionsArray}
            // editable={{}}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabase;
