import React, { Component } from "react";

// core components
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// database
import firebase from "../../firebase";

import TablePagination from "@material-ui/core/TablePagination";

import { CsvBuilder } from 'filefy'


// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabaseMorningSample extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
    };
  }


  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }


  async componentMountandUpdate() {
    this.setState({ loaded: false });

    let theColumnArray = [];
    let newList = [];
    let entriesArray = [];

    

    if (this.props.theDbRef === "morningSample") {

      console.log("******* MORNING SAMPLE SCREEN ******* ")



    /***** Determine start and end time check UTC values */
      /*
       get current date
       determine unix time at midnight 
       determine start and end time
      */


      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      const currentDate = new Date().toLocaleDateString('en-GB', options)

      const startDateTime = new Date(`${currentDate} 00:00:00`).getTime()

      console.log("new Date(`${currentDate} 00:00:00`) ==> ", new Date(`${currentDate} 00:00:00`))

      const endDateTime = startDateTime + 86400000
 
   
 
       /** Create a Morning Sample Check List */

      let deviceIDArray = new Array();
      
      const ref = firebase
      .database()
        .ref("status")
    
      ref.on('value', (snapshot) => {
      
      snapshot.forEach((machineSnapshot) => {
        const alchKey = machineSnapshot.key

        console.log("machine name is ", machineSnapshot.key)


        ref.child(alchKey).child('actions').child('MorningSample').limitToLast(1).on('value', (morningSampleSnapshot) => {

          let devicesLastTimestamp
          
          if (morningSampleSnapshot.val() !== null) {
            devicesLastTimestamp = Object.values(morningSampleSnapshot.val())[0]['timestamp'];
          }
          else {
            devicesLastTimestamp = -1
          }
          deviceIDArray.push([alchKey, devicesLastTimestamp])
        })

        
      })
        
       console.log(" ----  Morning Sample Check List   ------")
        console.table(deviceIDArray)
        

      
      /**************    CONVERT DATA INTO CORRECTLY FORMATED ARRAY  ****************/
      let morningSamplesCheckedArray = []

      deviceIDArray.forEach((deviceIs) => {
        
        let theDevice = deviceIs[0]
        let lastTimestampChecked = deviceIs[1]


        console.log("### Morning check")
        console.log("start date in Unix Epoch --> ", startDateTime)
        console.log("end date in Unix Epoch --> ", endDateTime)
        console.log("lastTimestampChecked ==> ", lastTimestampChecked)
        
          /* Check not new machine with no previous history */
        if (lastTimestampChecked === -1) {
            console.log("** NEW MACHINE **")
            morningSamplesCheckedArray.push(["dummy blank", {
              "Machine_ID": theDevice,
              "Morning Sample Check": "NEW DEVICE",
              "Last time done": new Date("January 1, 1970 00:00:00").toLocaleDateString()
            }])
          }
        /* has checked within 24hrs */
        else if (startDateTime <= lastTimestampChecked && lastTimestampChecked <= endDateTime) {
          console.log("** CHECK OK **")
            morningSamplesCheckedArray.push(["dummy blank", {
            "Machine_ID": theDevice,
            "Morning Sample Check": "done",
            "Last time done": new Date(lastTimestampChecked).toLocaleDateString('en-GB', options)
          }])
          }
            /* MISSING check within 24hrs */
        else {
          console.log("** MISSING **")
            morningSamplesCheckedArray.push(["dummy blank", {
              "Machine_ID": theDevice,
              "Morning Sample Check": "MISSING",
              "Last time done": new Date(lastTimestampChecked).toLocaleDateString('en-GB', options)
            }])
        }

      })
      

      entriesArray = [...morningSamplesCheckedArray]


      // sort by morning sample check
      entriesArray.sort(function (a, b) {

        let checkA = a[1]['Morning Sample Check'].toUpperCase() // ignore upper and lowercase
        let checkB = b[1]['Morning Sample Check'].toUpperCase()

        if (checkA < checkB) {
          return 1;
        }
        if (checkA > checkB) {
          return -1;
        }

        // both the same
        return 0;
      });



        
      console.log("ENTRIES ARRAY")

        console.table(entriesArray)
        




    // first row = [0], value [1]  gives the column heading:data objects for the first row.
    //Then find the keys for this to get the list of headings

    let lastEntry = entriesArray.length - 1;
    let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);

    const buildColObject = (theArrayOfHeadings) => {
      let anArrayForColumns = [];

      theArrayOfHeadings.forEach((theHeading, i) => {
        let thisCol = {
          title: theHeading,
          // cellStyle: (data, rowData) => {
          //       if (data === 'MISSING') {
          //         return { backgroundColor: "red", color: "White", fontWeight: "bold" }
          //     }
          // },
          field: camelize(theHeading),
          searchable: true,
          hidden:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // remember--> isAnObject === true if an object OR an array!
          isAnObject:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
          isALog: /log|Log/.test(theHeading) ? true : false,
          type: /date|Date/.test(theHeading)
            ? "date"
            : /timestamp|Timestamp/.test(theHeading)
              ? "datetime"
              : /time|Time|created/.test(theHeading)
                ? "datetime"
                : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                  ? "numeric"
                  : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                    ? "string"
                    : typeof entriesArray[lastEntry][1][theHeading],
        };

        if (thisCol.isAnObject) {
          thisCol.editComponent = (props) => (
            <textarea
              type="text"
              multiline={true}
              rows={10}
              value={props.value}
              onChange={(e) => {
                if (JSON.parse(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          );
        }

        anArrayForColumns = [...anArrayForColumns, thisCol];
      });

      // tag ons
      anArrayForColumns = [
        {
          title: "Selected",
          field: "selected",
          type: "boolean",
          hidden: false,
          isAnObject: false,
          searchable: true,
        },

        ...anArrayForColumns,

        // {
        //   title: "Unique Db Id",
        //   field: "uniqueDbId",
        //   type: "string",
        //   hidden: true,
        //   isAnObject: false,
        //   searchable: true,
        // },
        {
          //   title: "group",
          //   field: "group",
          //   // defaultGroupOrder: 0,
          //   defaultGroupSort: "asc",
          //   hidden: true,
          //   isAnObject: false,
          // searchable: true,
        },
      ];

      return anArrayForColumns;
    };

    theColumnArray = buildColObject(arrayOfColHeadings);

    let newRowData = [];

    entriesArray.forEach((childEntry, count) => {
      let parentID = childEntry[0];

      let dataObjectForThisRow = childEntry[1];

      theColumnArray.forEach((heading) => {
        if (heading.title === "Unique Db Id") {
          newRowData.push(parentID);
        } else if (heading.title === "Selected") {
          newRowData.push(false);
        } else if (!heading.hidden) {
          newRowData.push(dataObjectForThisRow[heading.title]);
        } else if (heading.isAnObject) {
          newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
        }
      });

      newList = [...newList, newRowData];
      newRowData = []; // clear
    });

    let curState = this.state;
    curState.colArray = theColumnArray;
    curState.tableData = newList;
    curState.loaded = true;
    this.setState(curState);

    console.log("++++  STATE  +++++")
    console.table(this.state)

      
      })


    }
  }



  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }


  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }


  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {

      const listStyle = {
        padding:"1ch 5vw"
      }


      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li style={listStyle}>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };

    /*

    const renderTheDetails = (rowData) => {


      const paperStyles = {
        padding: "2vh",
        textAlign: "center",
        color: "red",
        backgroundColor: "blue",
      };



      const EditDetailObjButt = (props) => {

        const updateCallback = () => {

          console.log("++++++  EditableDetailObjectModal update screen ++++++")

          this.setState((prevState) => ({
            updateFirebaseDb: !prevState.updateFirebaseDb,
          }))

        }

        const editableDetailsObjectsSet = new Set()
        editableDetailsObjectsSet.add("Address")
        editableDetailsObjectsSet.add("Address Data")

        console.log("  EditDetailObjButt props == ", props.title)
        console.log("editableDetailsObjectsSet.has(title) --> ", editableDetailsObjectsSet.has(props.title))

        let detailedRef = (this.props.theDbRef !== "cartridges/records") ? (`${this.props.theDbRef}/${rowData.uniqueDbId}/${props.title}`) : (`${this.props.theDbRef}/${rowData.uniqueDbId}/cartridgeData/${props.title}`)

        return ( editableDetailsObjectsSet.has(props.title) ?
          <div
          >
            <EditableDetailObjectModal
          theTitle={props.title}
              theDbRef={detailedRef}
              toUpdate={updateCallback}
        />
          </div>
          : null
        )
      }


      const renderDetailsCardOfAnObject = (theTitle, elementObject, index) => {


        console.log("theTitle  --> ", theTitle);
        console.log("elementObject  --> ", elementObject);
        console.log("index  --> ", index);
        console.log("=================================");

        return (
          <div>
            <Card key={`${theTitle}__${index}`}>
              <Paper className={paperStyles.paper}>
                {elementObject === null ? null : (
                  <RenderSingleObject elementObject={elementObject} />
                )}
              </Paper>
            </Card>
          </div>
        );
      };

      const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
        return theCardArrayRow.map((element, index) => {
          return (
            <div
              key={`row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`}
            >
              {element !== null ? (
                <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
              ) : null}
              {typeof element === "object" && typeof element !== "isALog"
                ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
                // They need to be rendered differently as map() only works for an array
                renderDetailsCardOfAnObject(theTitle, element, index)
                : element}
            </div>
          );
        });
      };

      const renderTheCardDetails = (detailCard, detailCardIndex) => {
        if (rowData[this.state.colArray[detailCardIndex].field] === undefined) {

          return (
            <div>
              <Card
                key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
              >
                <CardHeader style={{ backgroundColor: "#888" }}>
                  <h5>{this.state.colArray[detailCardIndex].title}</h5>
                </CardHeader>
                <CardBody>
                  <Paper className={paperStyles.paper}>
                    <div>
                      <h6>Unknown</h6>
                    </div>
                  </Paper>
                </CardBody>
              </Card>
            </div>
          )
        }
        else
        return (
          <div>
            <Card
              key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
            >
              <CardHeader color="rose">
                <h5>{this.state.colArray[detailCardIndex].title}</h5>
                <EditDetailObjButt title={this.state.colArray[detailCardIndex].title} />
              </CardHeader>
              <CardBody>
                <Paper className={paperStyles.paper}>
                  {console.log(
                    "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
                  )}
                  {Array.isArray(
                    JSON.parse(
                      rowData[this.state.colArray[detailCardIndex].field]
                    )
                  ) === true
                    ? outputCardFromArray(
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex,
                      this.state.colArray[detailCardIndex].title
                    )
                    : renderDetailsCardOfAnObject(
                      this.state.colArray[detailCardIndex].title,
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex
                    )}
                </Paper>
              </CardBody>
            </Card>
          </div>
        );
      };

      const getSpecicTitle = (rowData) => {
        let specificTitleInfo = "";

        return specificTitleInfo;
      };

      return (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            color: "black",
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <div>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              p={1}
              justifyContent="space-around"
            >
              {this.state.colArray.map((detailCard, detailCardIndex) => {
                if (detailCard.isAnObject && detailCard.isALog === false) {
                  {
                    return renderTheCardDetails(detailCard, detailCardIndex);
                  }
                } else if (detailCard.isALog === true) {
                  return (
                   (detailCard.title === "Cartridge Installation Log")
                      ?
                      <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                      >
                        {console.log("  SPECIFIC LOG  --- Cartridge Installation Log  ")}
                      <LogModalCartridgeInstalled
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                      </div>
                      :
                      <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                      >
                      <LogModal
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`}
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                    </div>
                  )
                

                }
              })}
            </Box>
          </div>
        </div>
      );
    };

  */
  
    
    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");
          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)
          console.log("*****************************\n");
          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },
      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
    ];

      
      

    const renderTheTable = () => {
      return (
        <div>
          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}

            //onRowClick={(event, rowData) => console.log(rowData)} // ***  DEBUG ONLY --- USEFULL!!

            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 50, 500]}
                />
              ),
            }}

            options={{
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              // selection: true,
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : (rowData['morningSampleCheck'] === 'MISSING')
                    ? "#F00"
                    : (rowData['morningSampleCheck'] === 'NEW DEVICE')
                        ? "#00F"
                        : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : (rowData['morningSampleCheck'] === 'MISSING')
                    ? "#FFF"
                    : (rowData['morningSampleCheck'] === 'NEW DEVICE')
                      ? "#FFF"
                      : "#111",
                fontWeight: (rowData['morningSampleCheck'] === 'MISSING')
                  ? "bold"
                  : (rowData['morningSampleCheck'] === 'NEW DEVICE')
                      ? "bold"
                      : "normal"
              }),
              // exportCsv: (columns, data) => {
              //   alert("Own export" + data.length + " rows");
              // },
            }}
            actions={theActionsArray}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabaseMorningSample;
