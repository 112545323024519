import firebase from "../firebase.js";

const alchemistBlankTemplateDbRef = firebase
  .database()
  .ref()
  // .child("alchemists/blankTemplate");
  .child("blankTemplate/alchemists_blankTemplate");

const makeNewAlchsBlankTemplate = (numberOfFakeAlchemists) => {
  let AlchDummy = {
    Address: {
      City: "--none--",
      Country: "--none--",
      "Post Code": "--none--",
    },
    "Allowed Cartridge IDs for Pump  (1-10)": {
      "Pump 0": 0,
      "Pump 1": 0,
      "Pump 2": 0,
      "Pump 3": 0,
      "Pump 4": 0,
      "Pump 5": 0,
      "Pump 6": 0,
      "Pump 7": 0,
      "Pump 8": 0,
      "Pump 9": 0,
    },

    "Application Type": "--none--",
    "Approved Pack Height (cm)": 1,
    // "Approved Pack Id": "P0055",
    "Approved Pack Size (ml)": 1,
    Brand: "--none--",
    Customer: "--none--",
    // "installation date": 1610668800000,
    "Light level at Installation (lumens)": 0,
    "Store name": "--none--",
  };

  AlchDummy = {
    ...AlchDummy,
    "Device Id": "--none--",
    "Customer Id": "--none--",
    "Formulation Id allowed": "--none--",
    "Location Id": "--none--",
    "Approved Pack Id": "--none--",
    "Person Installed By": "--none--",
    Retailer: "--none--",
    "installation date": 11577600,
  };

  // alchemistBlankTemplateDbRef.set({}); //clear any previous

  const query = alchemistBlankTemplateDbRef.orderByKey();

  const makeAlchsLoop = async (_) => {
    for (
      let alchemistCount = 0;
      alchemistCount < numberOfFakeAlchemists;
      alchemistCount++
    ) {
      try {
        await alchemistBlankTemplateDbRef.push(AlchDummy);
      } catch {
        console.log(
          " Alchemist dB promise failed --> is alchemist json incorrect ?"
        );
      }
      console.log("Alchemist pushed to Alchemis database --> ", alchemistCount);
    }

    query.once("value").then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        let parentId = childSnapshot.key;

        let CartridgeInfo = {
          "Cartridge ID": 0,
          "Certification of Analysis": "url//xxxxxx.pdf",
          Density: 1,
          "Expiry date": 11577600,
          "Formulation name": "--none--",
          "Manufacture date": 11577600,
          "Manufacture Address": {
            city: "--none--",
            country: "--none--",
            "post code": "--none--",
          },
          "Manufacture name": "--none--",
          "Steps per 10g": 1,
          "Supplier name": "--none--",
          Viscosity: 1,
          "Current Weight (g)": 1,
          "Warning weight (g)": 1,
        };

        let genEntry = {
          timestamp: 11577600,
          Notes: "This is  where notes go",
          Flags: "TBD",
        };

        for (let installCount = 0; installCount < 1; installCount++) {
          let cartInstall = {};

          cartInstall = {
            ...cartInstall,
            "Pump number changed": 0,
            timestamp: 11577600,
            "Person who changed cartridge": "--none--",
            "Cartridge batch ID": "--none--",
            "Cartridge name": "--none--",
            Description: "--none--",
            "Manufacture ID": "--none--",
            "Formulation ID": "--none--",
            "Supplier location": "--none--",
            ...CartridgeInfo,
          };

          alchemistBlankTemplateDbRef
            .child(parentId)
            .child("Cartridge Installation Log")
            .push(cartInstall);
        }

        for (
          let productMadeCount = 0;
          productMadeCount < 1;
          productMadeCount++
        ) {
          let productMade = {};

          productMade = {
            ...productMade,
            "Pump number changed": 0,
            timestamp: 11577600,
            "Person who changed cartridge": "--none--",
            "Cartridge State": {
              stuff1: "TBD",
              stuff2: "TBD",
              stuff3: "TBD",
            },
            "Recipe Version": "--none--",
            ...CartridgeInfo,
          };

          alchemistBlankTemplateDbRef
            .child(parentId)
            .child("Product Manufacture Log")
            .push(productMade);

          alchemistBlankTemplateDbRef
            .child(parentId)
            .child("Active Cartridges")
            .set({
              "Pump 0": "----",
              "Pump 1": "----",
              "Pump 2": "----",
              "Pump 3": "----",
              "Pump 4": "----",
              "Pump 5": "----",
              "Pump 6": "----",
              "Pump 7": "----",
              "Pump 8": "----",
              "Pump 9": "----",
            });

          for (let genLog = 0; genLog < 1; genLog++) {
            alchemistBlankTemplateDbRef
              .child(parentId)
              .child("General Logs")
              .push(genEntry);
          }
        }
      });
    });
  };

  makeAlchsLoop();
};

export { makeNewAlchsBlankTemplate };
