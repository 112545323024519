import React, { Component } from "react";

// core components
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "@material-ui/icons/Delete";

// database
import firebase from "../../firebase";
import TablePagination from "@material-ui/core/TablePagination";
import { CsvBuilder } from 'filefy'

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabaseUsersList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      blankTemplate: {},
      deleteRowsAllowed: false,
      addGeneralLogs: false
    };
  }


  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }



  async componentMountandUpdate() {
    this.setState({ loaded: false });

    let theColumnArray = [];
    let newList = [];
    let entriesArray;


    /**  Capture all the data from Firebase  */
      await firebase
        .database()
        .ref(this.props.theDbRef)
        .once("value", (snapshot) => {
          entriesArray = Object.entries(snapshot.val());
        });
  
    
    
    /**** Get all headings into a set ****/
    let headingSet = new Set()
  
    entriesArray.forEach(entry => {
        let getAllTheHeadings = Object.keys(entry[1])

          getAllTheHeadings.forEach(heading => {
            headingSet.add(heading)
          });

    });
    

    let arrayOfColHeadings = Array.from(headingSet)

    arrayOfColHeadings.sort();

    console.log("array Of Col Headings ----> ")
    console.table(arrayOfColHeadings)

    
    
    let allTheEntries = []

    for (let entryIndex = 0; entryIndex < entriesArray.length; entryIndex++) {
      
      /* If adminWriteAccess value hasnt been putin the database it defaults to false 
      Note: security rules are also in place from the db side 
      */
        if (entriesArray[entryIndex][1]['adminWriteAccess'] === undefined) {
          entriesArray[entryIndex][1]['adminWriteAccess'] = false
        }

            allTheEntries.push(entriesArray[entryIndex])
      }

  
    entriesArray = [...allTheEntries]

    console.log("**  Users Entries Array ==>  ");
    console.table(entriesArray);


    let lastEntry = entriesArray.length - 1;


    const buildColObject = (theArrayOfHeadings) => {
      let anArrayForColumns = [];

      theArrayOfHeadings.forEach((theHeading, i) => {
        let thisCol = {
          title: theHeading,
          field: camelize(theHeading),
          searchable: true,
          hidden:
            // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
            // /log|Log/.test(theHeading) === false
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // remember--> isAnObject === true if an object OR an array!
          isAnObject:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
          isALog: /log|Log/.test(theHeading) ? true : false,
          type: /date|Date/.test(theHeading)
            ? "date"
            : /timestamp|Timestamp/.test(theHeading)
              ? "datetime"
              : /time|Time|created/.test(theHeading)
                ? "datetime"
                : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                  ? "numeric"
                  : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                    ? "string"
                    : typeof entriesArray[lastEntry][1][theHeading],
        };

        if (thisCol.isAnObject) {
          thisCol.editComponent = (props) => (
            <textarea
              type="text"
              multiline={true}
              rows={10}
              value={props.value}
              onChange={(e) => {
                if (JSON.parse(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          );
        }

        anArrayForColumns = [...anArrayForColumns, thisCol];
      });

      // tag ons
      anArrayForColumns = [
        {
          title: "Selected",
          field: "selected",
          type: "boolean",
          hidden: false,
          isAnObject: false,
          searchable: true,
        },

        ...anArrayForColumns,

        {
          title: "Unique Db Id",
          field: "uniqueDbId",
          type: "string",
          hidden: true,
          isAnObject: false,
          searchable: true,
        },
        {
          //   title: "group",
          //   field: "group",
          //   // defaultGroupOrder: 0,
          //   defaultGroupSort: "asc",
          //   hidden: true,
          //   isAnObject: false,
          // searchable: true,
        },
      ];

      return anArrayForColumns;
    };

    theColumnArray = buildColObject(arrayOfColHeadings);

    let newRowData = [];

    entriesArray.forEach((childEntry, count) => {
      let parentID = childEntry[0];

      let dataObjectForThisRow = childEntry[1];

      theColumnArray.forEach((heading) => {
        if (heading.title === "Unique Db Id") {
          newRowData.push(parentID);
        } else if (heading.title === "Selected") {
          newRowData.push(false);
        } else if (!heading.hidden) {
          newRowData.push(dataObjectForThisRow[heading.title]);
        } else if (heading.isAnObject) {
          newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
        }
        // else newRowData.push(dataObjectForThisRow[heading.title]);
      });

      newList = [...newList, newRowData];
      newRowData = []; // clear
    });

    let curState = this.state;
    curState.colArray = theColumnArray;
    curState.tableData = newList;
    curState.loaded = true;
    this.setState(curState);
  }


  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }

  
  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }


  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {

      const listStyle = {
        padding:"1ch 5vw"
      }


      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li style={listStyle}>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };




    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },
      {
        tooltip: "Allow rows to be deleted",
        icon: () => <DeleteIcon />,
        isFreeAction: true,
        onClick: (evt, data) => {
          console.log("allow delete rows");
          this.setState((prevState) => ({
            deleteRowsAllowed: !prevState.deleteRowsAllowed,
          }));
        },
      },
      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
    ];





    const renderTheTable = () => {
      return (
        <div>
          {/*this.state.showModal ? (
            <LogModal
            // theTitle="Cartridge Log TEST!!"
            // theDbRef="cartridges/"
            />
          ) : null*/}

          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            // detailPanel={[
            //   {
            //     tooltip: "Show Extra Details",
            //     render: renderTheDetails,
            //   },
            // ]}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 50, 500]}
                />
              ),
            }}
            options={{
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              // selection: true,
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              // exportCsv: (columns, data) => {
              //   alert("Own export" + data.length + " rows");
              // },
            }}
            // onSelectionChange={(rows) =>
            //   console.log("You selected -->  rows ", rows)
            // }
            actions={theActionsArray}
            editable={{
              onRowAdd:
                this.props.theDbRef !== "users"
                  ? (newData) =>
                    new Promise((resolve, reject) => {
                      console.log(
                        "\nthis.props.theDbRef ===>> ",
                        this.props.theDbRef
                      );

                      console.log(
                        "this.state.blankTemplate  --> ",
                        this.state.blankTemplate
                      );

                      firebase
                        .database()
                        .ref(this.props.theDbRef)
                        .push(this.state.blankTemplate, (error) => {
                          if (error) {
                            alert(
                              "Sorry, had a problem adding row to the database. Check if online"
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });
                    })
                  : null,

              onRowUpdate:
                this.props.theDbRef !== "cartridges/records"
                  ? (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log("NORMAL SCREEEN UPDATE -- WORKS!!");

                      console.log("oldData ===>> ", oldData);

                      console.log("newData ===>> ", newData);

                      console.log("id ==  ", oldData.tableData.id);

                      console.log("this.state.tableData == > >");
                      console.table(this.state.tableData);

                      let newRowBuild = {};

                      let theData = Object.entries(newData);

                      theData.map((element, theIndex) => {
                        console.log("element --> ", element);
                        console.log("theIndex  --> ", theIndex);

                        //let fieldName = element[0];
                        let dataIs = element[1];
                        let titleName = this.state.colArray[theIndex].title;

                        if (
                          /Date/.test(titleName) ||
                          /date/.test(titleName)
                        ) {
                          //   need to check if date/time to parse to a timestamp
                          console.log("date --> Date.parse(dataIs)");
                          console.log(Date.parse(dataIs));
                          dataIs = Date.parse(dataIs);
                        } else if (
                          /time/.test(titleName) ||
                          /Time/.test(titleName) ||
                          /timestamp/.test(titleName) ||
                          /Timestamp/.test(titleName)
                        ) {
                          //   need to check if time to parse to a timestamp
                          //dataIs = new Date(dataIs).getTime();
                          dataIs = Date.parse(dataIs);
                        } else if (
                          this.state.colArray[theIndex].type === "numeric"
                        ) {
                          dataIs = parseFloat(dataIs);
                        } else if (/\{/.test(dataIs)) {
                          // check if its an object
                          dataIs = JSON.parse(dataIs);
                        }

                        newRowBuild[titleName] = dataIs;
                      });

                      console.log("  newRowBuild ==>");
                      console.table(newRowBuild);

                      const oldDataWithUniqueDbIdAndGroupColDeleted = (
                        theOldDataObject
                      ) => {
                        let newDataObj = { ...theOldDataObject };
                        delete newDataObj.tableData;
                        delete newDataObj["Unique Db Id"];
                        delete newDataObj["Selected"];
                        delete newDataObj.group;
                        return newDataObj;
                      };

                      let newUpdated = oldDataWithUniqueDbIdAndGroupColDeleted(
                        newRowBuild
                      );

                      console.log(
                        "and what is being pushed to firebase is -->"
                      );
                      console.table(newUpdated);

                      console.log(
                        "old Data row ==>  this.state.tableData[oldData.tableData.id] --> ",
                        this.state.tableData[oldData.tableData.id]
                      );

                      firebase
                        .database()
                        .ref(this.props.theDbRef)
                        .child(oldData.uniqueDbId)
                        .set(newUpdated, (error) => {
                          if (error) {
                            reject(
                              alert(
                                "Sorry, had a problem updating to the database. Check if online"
                              )
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });
                    })
                  : (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(
                        "*****    UPDATE ROW  FOR CARTRIDGES  *******"
                      );
                      console.log(
                        "this.state.updateFirebaseDb",
                        this.state.updateFirebaseDb
                      );

                      let newRowBuild = {};

                      let theData = Object.entries(newData);

                      console.log("Cartridge --> update Data entries array");
                      console.table(theData);

                      theData.map((element, theIndex) => {
                        //let fieldName = element[0];
                        let dataIs = element[1];
                        let titleName = this.state.colArray[theIndex].title;

                        if (
                          /Date/.test(titleName) ||
                          /date/.test(titleName)
                        ) {
                          //   need to check if date/time to parse to a timestamp
                          console.log(
                            `date --> Date.parse(dataIs) ${Date.parse(
                              dataIs
                            )}   on index number ${theIndex}`
                          );
                          dataIs = Date.parse(dataIs);
                        } else if (
                          /time/.test(titleName) ||
                          /Time/.test(titleName) ||
                          /timestamp/.test(titleName) ||
                          /Timestamp/.test(titleName)
                        ) {
                          //   need to check if time to parse to a timestamp
                          console.log("timestamp --> dataIs");
                          dataIs = new Date(dataIs).getTime();
                        } else if (
                          this.state.colArray[theIndex].type === "numeric"
                        ) {
                          dataIs = parseFloat(dataIs);
                        } else if (/\{/.test(dataIs)) {
                          // check if its an object
                          dataIs = JSON.parse(dataIs);
                        }

                        newRowBuild[titleName] = dataIs;
                      });

                      console.log(
                        "Cartridge Update \n====== newRowBuild ==="
                      );
                      console.table(newRowBuild);

                      let revisedNewRowObject = {};

                      revisedNewRowObject["Available"] =
                        newRowBuild["Available"];
                      revisedNewRowObject["Inserted or used"] =
                        newRowBuild["Inserted or used"];

                      if (newRowBuild["General Logs"] !== undefined) {
                        revisedNewRowObject["General Logs"] =
                          newRowBuild["General Logs"];
                      }

                      delete newRowBuild["Available"];
                      delete newRowBuild["Inserted or used"];
                      delete newRowBuild["General Logs"];
                      delete newRowBuild.tableData;
                      delete newRowBuild["Unique Db Id"];
                      delete newRowBuild["Selected"];
                      delete newRowBuild.group;

                      let cartridgeData = { ...newRowBuild };

                      revisedNewRowObject["cartridgeData"] = cartridgeData;
                      console.log("*** cartridgeData ==>");
                      console.table(cartridgeData);

                      console.log(
                        "*****   UPDATE OUTPUT  revisedNewRowObject ==>"
                      );
                      console.table(revisedNewRowObject);

                      firebase
                        .database()
                        .ref(this.props.theDbRef)
                        .child(oldData.uniqueDbId)
                        .set(revisedNewRowObject, (error) => {
                          if (error) {
                            reject(
                              alert(
                                "Sorry, had a problem updating to the database. Check if online"
                              )
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });
                    }),
              onRowDelete: this.state.deleteRowsAllowed
                ? (oldData) =>
                  new Promise((resolve, reject) => {
                    console.log("*****    DELETE DELETE  *******");

                    console.log("-->  oldData --> ", oldData);

                    console.log(
                      `this.props.theDbRef/oldData.uniqueDbId    ${this.props.theDbRef}/${oldData.uniqueDbId}`
                    );

                    console.log("===============");

                    let removedRowTableData = [...this.state.tableData];

                    const i = removedRowTableData.indexOf(
                      oldData.tableData.id
                    );

                    removedRowTableData.splice(i, 1);

                    this.setState({
                      tableData: removedRowTableData,
                    });

                    firebase
                      .database()
                      .ref(this.props.theDbRef + "/" + oldData.uniqueDbId)
                      .remove((error) => {
                        if (error) {
                          alert(
                            "Sorry, had a problem updating to the database. Check if online"
                          );
                        } else {
                          resolve(
                            this.setState((prevState) => ({
                              updateFirebaseDb: !prevState.updateFirebaseDb,
                            }))
                          );
                        }
                      });
                  })
                : null,
            }}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabaseUsersList;
