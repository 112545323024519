import React, { Component, useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import firebase from "../../firebase.js";
// import Button from "@material-ui/core/Button";
// import ButtonGroup from "@material-ui/core/ButtonGroup";

// import Box from "@material-ui/core/Box";

// import TableDatabase from "../TableDbComp/Table-Database";
import TableDatabaseUsersList from "../TableDbComp/TableDatabaseUsersList";
// import EmailPassLoginModal from "../../components/DialogModal/emailPassLoginModal";
// import MaxWidthDialog from "../../components/DialogModal/dialogModalBasic";
import NewSignInModal from "../../components/DialogModal/newSIgnInModal";

import { firebaseCreateNewUserButDontChangeCurrentUserLogedIn } from "../../firebase";

// export default UsersList;

const styles = {
  headerColor: {
    // backgroundColor: "#622773",
    // backgroundColor: "#51658C",
    // backgroundColor: "#F2F2F2",
    // backgroundColor: "#A6A6A6",
    // backgroundColor: "#66bb6a",
    // backgroundColor: "#ffa726",
    backgroundColor: "#ef3350",
    //backgroundColor: "#26c6da",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: "bold",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "45%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardCartridgeCountTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCartridgeCountValue: {
    fontSize: "2rem",
    margin: "0",
  },
};

const useStyles = makeStyles(styles);

const UsersList = (props) => {
  const classes = useStyles();

  const [newUserState, setNewUserState] = useState({
    password: "",
    email: "",
    showPassword: false,
    name: "",
    "retail Id": "",
    adminStatus: "",
  });

  const [toSubmitt, setToSubmitt] = useState(false);

  const getNewSignInEmailPassVal = (theValues) => {
    setNewUserState({
      password: theValues.password,
      email: theValues.email,
      name: theValues.name,
      retailId: theValues.retailId,
      adminStatus: theValues.adminStatus,
    });
    console.log("-------------  SEND TO DATABASE NEW USER   --------");
    console.table(theValues);
  };

  const setTheSubmitt = (submittValue) => {
    setToSubmitt(submittValue);
  };

  useEffect(() => {
    // check if admin state when componentMount
    if (toSubmitt === true && newUserState.email !== "") {
      console.log("*************************************")
      console.log("*************************************")
      console.log("Attempt to create an account  --> newUserState ");
      console.log(`
      newUserState.name   ${newUserState.name}\n
      newUserState.email  ${newUserState.email}\n
      newUserState.password  ${newUserState.password}\n
      newUserState.retailId  ${newUserState.retailId}\n
      newUserState.adminStatus  ${newUserState.adminStatus}`);
      console.log("*************************************")
      console.log("*************************************")


      // Attempt to create an account
      firebaseCreateNewUserButDontChangeCurrentUserLogedIn(
        newUserState.name,
        newUserState.email,
        newUserState.password,
        newUserState.retailId,
        newUserState.adminStatus,
        (err) => {
          console.log(
            "error creating new users in firebaseCreateNewUserButDontChangeCurrentUserLogedIn --> ",
            err
          );
        }
      );
    }
  });

  return (
    <div>
      <NewSignInModal
        newSignInEmailPasswordValuesCallback={getNewSignInEmailPassVal}
        toSubmittCallback={setTheSubmitt}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* <CardHeader color="primary"> */}
            <CardHeader style={styles.headerColor}>
              <h4 className={classes.cardTitleWhite}>Users List Table</h4>
              {/* <p className={classes.cardCategoryWhite}>Users List</p> */}
            </CardHeader>
            <CardBody>
              {/* <ContainedButtons /> */}
              {/* <TableDatabase theDbRef="users" theTitle="" />; */}
              <TableDatabaseUsersList theDbRef="users" theTitle="" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default UsersList;
