import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "@material-ui/core/Button";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableContainer from "@material-ui/core/TableContainer";
// import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    tableCallback,
    tableEditCallback,
    // tableClearSelectCallback,
    tableSelectCallback,
  } = props;

  // callback from table child to parent screen when heading is pressed
  const sortPressed = (headingPressed) => {
    tableCallback(headingPressed);
  };

  const editPressed = (theEditButt) => {
    tableEditCallback(theEditButt);
  };

  const clearSelectedPressed = () => {
    // tableClearSelectCallback(theClearSelectButt)
    console.log("CLEAR SELECT PRESSED");
  };

  const selectPressed = (theSelectButt) => {
    tableSelectCallback(theSelectButt);
  };

  // May convert this into a component so can be used in other places or seperatly
  const tableTools = (
    // needs proper styling
    <div className={classes.root}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => clearSelectedPressed()}
      >
        CLEAR SELECT
      </Button>
      <Button variant="contained" color="primary" onClick={() => editPressed()}>
        EDIT
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          alert("Find button clicked");
        }}
      >
        FIND
      </Button>
    </div>
  );

  return (
    <div className={classes.tableResponsive}>
      {tableTools}

      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                // ** TODO ! need to add a check if the column is in the columsNotToBeDisplayed array
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => sortPressed(prop)}
                    >
                      {prop}
                    </Button>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            let theRowNumber = key;
            let theRowStyle;
            let theCellStyle;

            if (prop[0] === "on") {
              theRowStyle = classes.tableBodySelectedRow;
              theCellStyle = classes.tableCellSelected;
            } else {
              theRowStyle = classes.tableBodyRow;
              theCellStyle = classes.tableCell;
            }
            return (
              <TableRow key={key} className={theRowStyle}>
                {prop.map((prop, key) => {
                  if (key === 0) {
                    return (
                      <TableCell className={theCellStyle} key={key}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => selectPressed(theRowNumber)}
                        >
                          {prop}
                        </Button>
                      </TableCell>
                    );
                  } else
                    return (
                      <TableCell className={theCellStyle} key={key}>
                        {prop}
                      </TableCell>
                    );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
