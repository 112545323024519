import React, { Component, useMemo } from "react";
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// database
import firebase from "../../firebase";
import TablePagination from "@material-ui/core/TablePagination";
import { CsvBuilder } from 'filefy'

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};


class TableDatabaseActivePumpStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      activeCartsKeyPairs: [],
      cartInfoArray: [],
      cleanedupInfoArray : [],
    }
  }



  async componentMountandUpdate() {
    this.setState({ loaded: false });
    


  let theColumnArray, newList = []
  let entriesArray = []

    console.log("******* ACTIVE PUMP STATUS TABLE ******* ")
    
    console.log("theDbRef  ====> ", this.props.theDbRef)
    console.log("theTitle  ====> ", this.props.theTitle)


    let deviceUid = this.props.theDbRef.split('/')[2]
    console.log("Alchemist we want is ==> ", deviceUid)


// NOTE: This is different than other TableDatabase screens as multiple firebase calls so neeed to make sure async/await is firing correctly 
    
    const finishOffMountAndUpdate = async () => {

                let cleanedUpActivePumpArray = []
      
                for (const [key, valueObject] of Object.entries(this.state.cartInfoArray)) {
                  console.log("key" ,key)
                  console.log("value", valueObject)

                  if (typeof valueObject !== 'object' || valueObject === null) {
                    console.log("EMPTY ===> ", key)

                    valueObject = {}

                    valueObject['Cartridge Name'] = 'NONE'
                    valueObject['Cartridge Batch Id'] = 'NONE'
                    valueObject['Cartridge Id'] = 'NONE'
                  }
                    
                  let addPumpNumber = {}

                  addPumpNumber['Pump'] = key
                  addPumpNumber['Cartridge Name'] = valueObject['Cartridge Name']
                  addPumpNumber['Cartridge Batch Id'] = valueObject['Cartridge Batch Id']
                  addPumpNumber['Cartridge Id'] = valueObject['Cartridge Id']
                  // addPumpNumber['Description'] = valueObject['Description']

                  delete valueObject.batch_id
                  delete valueObject.Description
                  delete valueObject['Cartridge Name']
                  delete valueObject.Density
                  delete valueObject['Cartridge Batch Id']
                  delete valueObject['Cartridge Id']
                  delete valueObject.Weight

                  // Pass all remaining key pairs of the value into the new addPumpNumber object
                  for (const property in valueObject) {
                    addPumpNumber[property] = valueObject[property]
                  }


                  cleanedUpActivePumpArray.push([key, addPumpNumber])

                }
    
                
                this.setState({cartInfoArray: cleanedUpActivePumpArray})

                entriesArray = this.state.cartInfoArray
                              
                              

                let lastEntry = entriesArray.length - 1;
                let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);

                const buildColObject = (theArrayOfHeadings) => {
                  let anArrayForColumns = [];

                  theArrayOfHeadings.forEach((theHeading, i) => {
                    let thisCol = {
                      title: theHeading,
                      field: camelize(theHeading),
                      searchable: true,
                      hidden:
                        // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
                        // /log|Log/.test(theHeading) === false
                        (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                          ? true
                          : false,
                      // remember--> isAnObject === true if an object OR an array!
                      isAnObject:
                        (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                          ? true
                          : false,
                      // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
                      isALog: /log|Log/.test(theHeading) ? true : false,
                      type: /date|Date/.test(theHeading)
                        ? "date"
                        : /timestamp|Timestamp/.test(theHeading)
                          ? "datetime"
                          : /time|Time|created/.test(theHeading)
                            ? "datetime"
                            : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                              ? "numeric"
                              : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                                ? "string"
                                : typeof entriesArray[lastEntry][1][theHeading],
                    };

                    if (thisCol.isAnObject) {
                      thisCol.editComponent = (props) => (
                        <textarea
                          type="text"
                          multiline={true}
                          rows={10}
                          value={props.value}
                          onChange={(e) => {
                            if (JSON.parse(e.target.value)) {
                              props.onChange(e.target.value)
                            }
                          }}
                        />
                      );
                    }

                    anArrayForColumns = [...anArrayForColumns, thisCol]
                  });

                  // tag ons
                  anArrayForColumns = [
                    {
                      title: "Selected",
                      field: "selected",
                      type: "boolean",
                      hidden: false,
                      isAnObject: false,
                      searchable: true,
                    },

                    ...anArrayForColumns,

                    // {
                    //   title: "Unique Db Id",
                    //   field: "uniqueDbId",
                    //   type: "string",
                    //   hidden: true,
                    //   isAnObject: false,
                    //   searchable: true,
                    // },
                    {
                      //   title: "group",
                      //   field: "group",
                      //   // defaultGroupOrder: 0,
                      //   defaultGroupSort: "asc",
                      //   hidden: true,
                      //   isAnObject: false,
                      // searchable: true,
                    },
                  ];

                  return anArrayForColumns;
                };

                theColumnArray = buildColObject(arrayOfColHeadings);

                let newRowData = []

                entriesArray.forEach((childEntry, count) => {
                  let parentID = childEntry[0]

                  let dataObjectForThisRow = childEntry[1]

                  theColumnArray.forEach((heading) => {
                    if (heading.title === "Unique Db Id") {
                      newRowData.push(parentID);
                    } else if (heading.title === "Selected") {
                      newRowData.push(false)
                    } else if (!heading.hidden) {
                      newRowData.push(dataObjectForThisRow[heading.title])
                    } else if (heading.isAnObject) {
                      newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]))
                    }
                    // else newRowData.push(dataObjectForThisRow[heading.title]);
                  });

                  newList = [...newList, newRowData]
                  newRowData = [] // clear
                });

                let curState = this.state
                curState.colArray = theColumnArray
                curState.tableData = newList
                curState.loaded = true
                this.setState(curState)
                              

    }




    const getExtraCartInfo = async (valueCartsInfoForSpecificPump, pump) => { 


                try {
                                
                                
                  let batch_key = valueCartsInfoForSpecificPump['batch_key']
                  console.log("batch_key --> ", batch_key)


                  let theFirebaseCartInfoRef = firebase.database().ref(`cartridges/records/${batch_key}`)
                  let getExtraCartInfoSnapshot = await theFirebaseCartInfoRef.once("value")
                  let valueExtraCartInfo = await getExtraCartInfoSnapshot.val()


                  if (valueExtraCartInfo["Cartridge Batch Id"] !== undefined || valueExtraCartInfo["Cartridge Batch Id"] !== null) {
                    this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]]["Cartridge Batch Id"] = valueExtraCartInfo["Cartridge Batch Id"] })
                  }


                  if (valueExtraCartInfo['cartridgeData']['Cartridge Id'] !== undefined || valueExtraCartInfo['cartridgeData']['Cartridge Id'] !== null) {
                    this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]]["Cartridge Id"] = valueExtraCartInfo['cartridgeData']['Cartridge Id'] })
                  }


                  if (valueExtraCartInfo['cartridgeData']['Cartridge Name'] !== undefined || valueExtraCartInfo['cartridgeData']['Cartridge Name'] !== null) {
                    this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]]["Cartridge Name"] = valueExtraCartInfo['cartridgeData']['Cartridge Name'] })
                  }


                  if (valueExtraCartInfo['cartridgeData']['Description'] !== undefined || valueExtraCartInfo['cartridgeData']['Cartridge Name'] !== null) {
                    this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]]["Description"] = valueExtraCartInfo['cartridgeData']['Description'] })
                  }


                  this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]]["Description"] = valueExtraCartInfo['cartridgeData']['Description'] })
            


              } catch (error2) {
                console.log(`TableDatabaseActivePumpStatus call ERROR --> `, error2)
                }

    }



    const getCarts = async () => {

      const firstThis = async ()=> {
        try {
        
          let theFirebaseAlchRef = firebase.database().ref(`alchemists/records/${deviceUid}/Active Cartridges`)
          let getActiveCartsInstalledSnapshot = await theFirebaseAlchRef.once("value")
          let valueActiveCartsInstalled = await getActiveCartsInstalledSnapshot.val()
          let activeCartsEntries = Object.entries(valueActiveCartsInstalled)
  
          this.setState({ activeCartsKeyPairs: activeCartsEntries })
  
          console.log("----   activeCartsKeyPairs   ----> ", this.state.activeCartsKeyPairs)
  
  
          try {
  
            for (const pump of this.state.activeCartsKeyPairs) {
  
              let pumpKey = pump[1]
  
              let theFirebaseCartPumpRef = firebase.database().ref(`alchemists/records/${deviceUid}/Cartridge Install Log/${pumpKey}`)
              let getCartInfoFromPumpSnapshot = await theFirebaseCartPumpRef.once("value")
              let valueCartsInfoForSpecificPump = await getCartInfoFromPumpSnapshot.val()
              this.setState(prevState => { cartInfoArray: prevState.cartInfoArray[pump[0]] = valueCartsInfoForSpecificPump })
              await getExtraCartInfo(valueCartsInfoForSpecificPump, pump)
            }

           
          }
          catch (error) {
            console.log(`TableDatabaseActivePumpStatus call ERROR --> `, error)
          }
        }
  
        catch (e) {
          console.log(`TableDatabaseActivePumpStatus Error Firebase alchemists/records/${deviceUid}/Active Cart Installed has a call ERROR --> `, e)
        }


        
      }

      await firstThis()
      await finishOffMountAndUpdate()


    }

      await getCarts()

}


  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }

  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }



  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {
      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };



    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },

      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
      
    ];




    const renderTheTable = () => {
      return (
        <div>
          <MaterialTable
            style={{minHeight : "auto"}}
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              ),
            }}
            options={{
              pageSize:10,
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "auto",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              //selection: true,
              exportButton: false,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              exportCsv: (columns, data) => {
                alert("Own export" + data.length + " rows");
              },
            }}
            // onSelectionChange={(rows) =>
            //   console.log("You selected -->  rows ", rows)
            // }
            actions={theActionsArray}
          />
        </div>
      );
    }
    return (
       <div style={{ maxWidth: "100%" }}>
      {/* <div style={{ maxWidth: "100%" }, {height : "90vh"}}> */}
      {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabaseActivePumpStatus;
