import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import firebase from "../../firebase.js";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import Box from "@material-ui/core/Box";
import TableDbCartridgeList from "../../views/TableDbComp/TableDbCartridgeList";


const cartridgesDbRef = firebase
  .database()
  .ref()
  .child("cartridges/");

const cartridgesCountRef = firebase
  .database()
  .ref()
  .child("cartridges/count");

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "45%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardCartridgeCountTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCartridgeCountValue: {
    fontSize: "2rem",
    margin: "0",
  },
};


const useStyles = makeStyles(styles);

//  STATE TO CONTAIN FILE ??
// If file dosnt exist then null render, else render and html5 downlad attribute for file

export function ContainedButtons() {
  // const classes = useButtonStyles();

  return (
    // <div className={classes.root}>
    <div>
      <Box
        display="flex"
        justifyContent="center"
        m={1}
        p={1}
      >
        <Box p={1}>
          <ButtonGroup
            variant="text"
            color="primary"
            aria-label="text primary button group"
          >
            <Button
              onClick={() => console.log("download")}
            >
              download
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

    </div>
  );
}

const CartridgesList = () => {

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cartridges</h4>
              <p className={classes.cardCategoryWhite}>Cartridges List</p>
            </CardHeader>
            <CardBody>
              <TableDbCartridgeList theDbRef="cartridges/records" theTitle="" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CartridgesList;
