import React, { useState, useEffect } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// import ButtonGroup from "@material-ui/core/ButtonGroup";

// import Box from "@material-ui/core/Box";

// import { flexbox } from "@material-ui/system";
import TableDatabaseCartridgeTally from "../TableDbComp/TableDatabaseCartridgeTally"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "45%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardCartridgeCountTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardCartridgeCountValue: {
    fontSize: "2rem",
    margin: "0",
  },
};

const useStyles = makeStyles(styles);


// const resetDateToOneMinPastMidnight = (theDate) => {
//     let hoursSet = new Date(theDate).setHours(1)
//     let minutesSet = new Date(hoursSet).setMinutes(1)
//     let secondsSet = new Date(minutesSet).setSeconds(0)
//     return new Date(secondsSet)
// }


// const ContainedButtons = (props) => {

//   const todaysDateMinusSevenDays = new Date().setDate(new Date().getDate() - 7)
//   const [selectedDateFrom, handleDateChangeFrom] = useState(resetDateToOneMinPastMidnight(new Date(todaysDateMinusSevenDays)))
//   const [selectedDateTo, handleDateChangeTo] = useState(resetDateToOneMinPastMidnight(new Date()));

//   const sendDatesToCartridgeTally = () => {
//     console.log("========  sendDatesToCartridgeTally ====")
//     props.parentCallback(selectedDateFrom, selectedDateTo)
//     console.log("parentCallback selectedDateFrom ==> ", selectedDateFrom)
//     console.log("parentCallback selectedDateTo ==> ", selectedDateTo)
//     console.log("======================================")
//   }


//   useEffect(() => {
//     sendDatesToCartridgeTally()
// })

//   return (
//     // <div className={classes.root}>
//     <div>
//       <Box
//         display="flex"
//         justifyContent="center"
//         m={1}
//         p={1}
//         // bgcolor="background.paper"
//       >
//         {/* <Box p={1} bgcolor="grey.300"> */}
//         <Box p={1} >
//           <MuiPickersUtilsProvider utils={DateFnsUtils}>

//           <KeyboardDatePicker
//               disableFuture
//               value={selectedDateFrom}
//               onChange={date => handleDateChangeFrom(date)}
//               minDate={new Date("2018-01-01T00:00")}
//               format="dd/MM/yyyy"
//               label="From"
//             />

//           <KeyboardDatePicker
//               disableFuture
//               value={selectedDateTo}
//               onChange={date => handleDateChangeTo(date)}
//               minDate={new Date("2020-01-01T00:00")}
//               format="dd/MM/yyyy"
//               label="To"
//           />
//           </MuiPickersUtilsProvider>

//           <ButtonGroup
//             variant="text"
//             color="primary"
//             aria-label="text primary button group"
//           >
//           </ButtonGroup>
//         </Box>
              
//       </Box>

//     </div>
//   );
// }


const CartridgeTally = (props) => {

  const classes = useStyles();


    // const [selectedDateInContainedButtonsChildFrom, handleDateInContainedButtonsChildFrom] = useState(new Date('2021-06-13'))
    // const [selectedDateInContainedButtonsChildTo, handleDateInContainedButtonsChildTo] = useState(new Date('2021-08-01'))

  
  // const callBackFindFunction = (childContainedButtonsDateFrom, childContainedButtonsDateTo) => {

  //     const validDateRegEx = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/


  //         if (validDateRegEx.test(childContainedButtonsDateFrom.toLocaleDateString())) {
  //           handleDateInContainedButtonsChildFrom(childContainedButtonsDateFrom)
  //           console.log("callBackFindFunction  selectedDateInContainedButtonsChildFrom --> ", selectedDateInContainedButtonsChildFrom)
  //         }
  //         if (validDateRegEx.test(childContainedButtonsDateTo.toLocaleDateString())) {
  //           handleDateInContainedButtonsChildTo(childContainedButtonsDateTo)
  //           console.log("callBackFindFunction  selectedDateInContainedButtonsChildTo --> ", selectedDateInContainedButtonsChildTo)
  //         }
  //   }


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cartridge Tally</h4>
            </CardHeader>
            {/* <ContainedButtons parentCallback={callBackFindFunction}/> */}
            <CardBody>
              {/* <TableDatabaseCartridgeTally theStartDate={selectedDateInContainedButtonsChildFrom} theFinishDate={selectedDateInContainedButtonsChildTo} theDbRef="CartridgeTally" theTitle="" /> */}
              <TableDatabaseCartridgeTally theDbRef="CartridgeTally" theTitle="" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CartridgeTally;
