/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
// import UserProfile from "views/UserProfile/UserProfile.js";
import CartridgeList from "views/CartridgeList/CartridgeList.js";
import CartridgeLevels from "views/CartridgeLevels/CartridgeLevels.js";
import AlchemistList from "views/AlchemistList/AlchemistList.js";
import ProductsList from "views/ProductsList/ProductsList.js";
import ProductsMade from "views/ProductsMade/ProductsMade.js";
import CartridgeTally from "views/CartridgeTally/CartridgeTally.js"
import MorningSampleCheck from "views/MorningSampleCheck/MorningSampleCheck.js";
import UsersList from "views/UsersList/UsersList";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    adminWriteAllowed: false,
  },
  {
    path: "/cartridges",
    name: "Cartridge List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: CartridgeList,
    layout: "/admin",
    adminWriteAllowed: true,
  },
  {
    path: "/alchemist",
    name: "Alchemist List",
    rtlName: "قائمة الجدول",
    icon: "blender",
    component: AlchemistList,
    layout: "/admin",
    adminWriteAllowed: true,
  },
  {
    path: "/Products",
    name: "Products List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: ProductsList,
    layout: "/admin",
    adminWriteAllowed: true,
  },
  {
    path: "/Users",
    name: "Users List",
    rtlName: "قائمة الجدول",
    icon: "people",
    component: UsersList,
    layout: "/admin",
    adminWriteAllowed: true,
  },
  {
    path: "/ProductsMade",
    name: "Products Made",
    rtlName: "قائمة الجدول",
    icon: "precision_manufacturing",
    component: ProductsMade,
    layout: "/admin",
    adminWriteAllowed: false,
  },
  {
    path: "/cartridgeLevels",
    name: "Cartridge Levels",
    rtlName: "قائمة الجدول",
    icon: "water",
    component: CartridgeLevels,
    layout: "/admin",
    adminWriteAllowed: false,
  },
  {
    path: "/MorningSampleCheck",
    name: "Morning Samples Check",
    rtlName: "قائمة الجدول",
    icon: "timelapse",
    component: MorningSampleCheck,
    layout: "/admin",
    adminWriteAllowed: false,
  },
  {
    path: "/CartridgeTally",
    name: "Cartridge Tally",
    rtlName: "قائمة الجدول",
    icon: "functions",
    component: CartridgeTally,
    layout: "/admin",
    adminWriteAllowed: false,
  },
];

export default dashboardRoutes;
