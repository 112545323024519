
/*******************************************************************
 * need to export a provider and context 
 * 
 * Provider needs to be wrapped around the <Switch in index.js ?? 
 * 
 * context needs to hold the state of the route (same as route.js), 
 * its return a filterd version according to the adminWriteeAccess flag setting
 * 
 * In Sidebar.js and Navbar.js routes is maped over,
 * so switch this from a prop to a context state
 * So needs context to return an array f routes route
 * 
 * 
 * Also need another context callback in index.js to signall a change in adminWrriteAccess  
 * Hence have a check in index.js of the adminWriteAccess , then via callback change route in the context
 * 
********************************************************************/





import React, { useState, createContext } from 'react';



export const ScreenRoutesContext = createContext()



export const ScreenRoutesContextProvider = (props) => {

    const routes = props.routes

    const allowedRoutes = [...routes]

    const [adminWriteAccess, setAdminWriteAccess] = useState(true)
    
    const setWriteAccess = (value) => {

        console.log(`\n****  adminWriteAccess  ${adminWriteAccess} *****\n`)

        setAdminWriteAccess(value)
    }

    const getAllowedRoutes = () => {

        let theDashboardRoutes = []

        if (adminWriteAccess) {
            theDashboardRoutes = [...allowedRoutes]
        }
        else {
            theDashboardRoutes = allowedRoutes.filter(routeElement => { return routeElement.adminWriteAllowed === false })
        }
        
        return theDashboardRoutes
    }


    return (
        <ScreenRoutesContext.Provider value={{ adminWriteAccess, setWriteAccess, getAllowedRoutes }}>
            { props.children }
        </ScreenRoutesContext.Provider>
    );

}
 