import firebase from "../firebase.js";

const alchemistDbRef = firebase
  .database()
  .ref()
  .child("alchemists/records");

const makeNewAlchs = (numberOfFakeAlchemists) => {
  let AlchDummy = {
    Address: {
      City: "Warrington",
      Country: "UK",
      "Post Code": "AAA111",
    },
    "Allowed Cartridge IDs for Pump  (1-10)": {
      "Pump 0": 2200011,
      "Pump 1": 1100000,
      "Pump 2": 1200000,
      "Pump 3": 1100001,
      "Pump 4": 1100002,
      "Pump 5": 2200005,
      "Pump 6": 1100004,
      "Pump 7": 2200005,
      "Pump 8": 1100006,
      "Pump 9": 1100009,
    },

    "Application Type": "Foundation",
    "Approved Pack Height (cm)": 15,
    // "Approved Pack Id": "P0055",
    "Approved Pack Size (ml)": 20,
    Brand: "ABCD",
    Customer: "AS Watson",
    // "installation date": 1610668800000,
    "Light level at Installation (lumens)": 0,
    "Store name": "Champ-Elysee",
  };

  let personArray = [
    "Bob Hoskins",
    "Susan B",
    "Michelle A",
    "Samantha E",
    "Andy T",
  ];

  const getPerson = () => {
    return personArray[Math.floor(Math.random() * personArray.length)];
  };

  let theRetailerArray = ["Avon", "Marionnaud", "SpaceNK"];

  const getRetailer = () => {
    return theRetailerArray[
      Math.floor(Math.random() * theRetailerArray.length)
    ];
  };

  const randtimestamp = () => {
    return Date.now() + Math.floor(Math.random() * 5000000000) + 500000;
  };

  AlchDummy = {
    ...AlchDummy,
    "Device Id": `D-${Math.floor(Math.random() * 1000)}`,
    "Customer Id": `C-${Math.floor(Math.random() * 1000)}`,
    "Formulation Id allowed": `F-${Math.floor(Math.random() * 1000)}`,
    "Location Id": `L-${Math.floor(Math.random() * 1000)}`,
    "Approved Pack Id": `P-${Math.floor(Math.random() * 1000)}`,
    "Person Installed By": getPerson(),
    Retailer: getRetailer(),
    "installation date": randtimestamp(),
  };

  //alchemistDbRef.set({}); //clear any previous

  const query = firebase
    .database()
    .ref()
    .child("alchemists/records")
    .orderByKey();

  const makeAlchsLoop = async (_) => {
    for (
      let alchemistCount = 0;
      alchemistCount < numberOfFakeAlchemists;
      alchemistCount++
    ) {
      try {
        await alchemistDbRef.push(AlchDummy);
      } catch {
        console.log(
          " Alchemist dB promise failed --> is alchemist json incorrect ?"
        );
      }
      console.log("Alchemist pushed to Alchemis database --> ", alchemistCount);
    }

    query.once("value").then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        let parentId = childSnapshot.key;

        let CartridgeInfo = {
          "Cartridge Id": 99,
          "Cartridge Batch Id": "--none--",
          "Cartridge Name": "--none--",
          "Certification Of Analysis": "pdf",
          "Date Catridge Installed": 475804800,
          Density: 99,
          Description: "--none--",
          "Expiry Date": 475804800,
          "Formulation Id": 99,
          "Formulation Name": "DCYPHER&MRN",
          "Manufacture Date": 475804800,
          "Address Data": {
            "Manufacturer Location":
              "Cornelius House, Woodside, Dunmow Road,Bishop's Stortford, Herts, CM23 5RG, UK",
            "Supplier Location": "Główna 1, 95-041 Gałków Duży, Polska",
          },
          "Manufacturer Id": "MID-xxxx",
          "manufacturer Name": "Passage Cosmetics Laboratory SA",
          "Steps Per 10g": 999,
          "Supplier Id": "SID-xxx",
          "Supplier Name": "Cornelius PLC",
          Viscosity: 99,
          Volume: 100,
          Weight: 999,
          "Warning Weight": 99,
          SDS: "pdf",
          "Micro Test": "pdf",
          CSPR: "pdf",
          "Formulation PIF": "pdf",
          "CPNP number": 1,
          "Ingredient Breakdown": "pdf",
        };

        let genEntry = {
          timestamp: 23299943,
          Notes: "This is  where notes go",
          Flags: "TBD",
        };

        const randPump = () => {
          return Math.floor(Math.random() * 10 + 1);
        };

        let cartridgeBatchIdArray = [
          "EMSN",
          "ENBO",
          "ENJO",
          "ENMA",
          "ENNI",
          "ENPL",
          "ENRE",
          "ENSE",
          "ENSU",
          "ENVI",
          "PABL",
          "PAMA",
          "PAMO",
          "PAPR",
          "PARA",
          "PARE",
          "PASH",
          "PAWH",
          "PAYE",
        ];

        const getCartridgeBatchId = () => {
          return `${
            cartridgeBatchIdArray[
              Math.floor(Math.random() * cartridgeBatchIdArray.length)
            ]
          }${Math.floor(Math.random() * 10000000)}`;
        };

        let cartNameArray = [
          "White",
          "Red",
          "Water",
          "Blue",
          "Cream",
          "Cheese",
          "Moist",
          "Jam",
          "Peach",
          "Snake V",
        ];

        const getCartName = () => {
          return cartNameArray[
            Math.floor(Math.random() * cartNameArray.length)
          ];
        };

        let personArray = [
          "Bob Hoskins",
          "Susan B",
          "Michelle A",
          "Samantha E",
          "Andy T",
        ];

        const randtimestamp = () => {
          return Date.now() + Math.floor(Math.random() * 5000000000) + 500000;
        };

        const getPerson = () => {
          return personArray[Math.floor(Math.random() * personArray.length)];
        };

        for (let installCount = 0; installCount < 17; installCount++) {
          let cartInstall = {};

          let description = getCartName();

          cartInstall = {
            ...cartInstall,
            "Pump number changed": randPump(),
            timestamp: randtimestamp(),
            "Person who changed cartridge": getPerson(),
            "Cartridge Batch Id": getCartridgeBatchId(),
            "Cartridge Name": description,
            Description: description,
            "Manufacturer Id": `MID-${Math.floor(Math.random() * 100)}`,
            //"Formulation ID": Math.floor(Math.random() * 4000),
            "Supplier location": `SID-${Math.floor(Math.random() * 100)}`,
            ...CartridgeInfo,
          };

          alchemistDbRef
            .child(parentId)
            .child("Cartridge Installation Log")
            .push(cartInstall);
        }

        for (
          let productMadeCount = 0;
          productMadeCount < 7;
          productMadeCount++
        ) {
          let productMade = {};

          productMade = {
            ...productMade,
            "Pump number changed": randPump(),
            timestamp: randtimestamp(),
            "Person who changed cartridge": getPerson(),
            "Cartridge State": {
              stuff1: "TBD",
              stuff2: "TBD",
              stuff3: "TBD",
            },
            "Recipe Version": "v1.2",
            ...CartridgeInfo,
          };

          alchemistDbRef
            .child(parentId)
            .child("Product Manufacture Log")
            .push(productMade);

          alchemistDbRef
            .child(parentId)
            .child("Active Cartridges")
            .set({
              "Pump 0": "MAa12wfjuzC5GN6KmmL",
              "Pump 1": "MAa12wgxDsi9gDItXAL",
              "Pump 2": "MAa12whEPN__3PLDqIF",
              "Pump 3": "MAa12whEPN__3PLDqIF",
              "Pump 4": "MAa12whEPN__3PLDqIG",
              "Pump 5": "MAa12wm7VZNE4QWFSFA",
              "Pump 6": "MAa12wm7VZNE4QWFSFB",
              "Pump 7": "MAXctuwJNzNbxUHrUGJ",
              "Pump 8": "MAXctuwJNzNbxUHrUGJ",
              "Pump 9": "MAXctuwJNzNbxUHrUGJ",
            });

          for (let genLog = 0; genLog < 2; genLog++) {
            alchemistDbRef
              .child(parentId)
              .child("General Logs")
              .push(genEntry);
          }
        }
      });
    });
  };

  makeAlchsLoop();
};

export { makeNewAlchs };
