import allRoutes from "../src/routes"

//Firebase
import firebase from "../src/firebase";
import {
  firebaseLogin,
  // firebaseRegisterAuthCallback,
  firebaseCreateAdminAccount,
} from "../src/firebase";

import dotenv from "dotenv";

// React
import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import EmailPassLoginModal from "components/DialogModal/emailPassLoginModal";
// import NewSignInModal from "components/DialogModal/newSIgnInModal";
// import { object } from "prop-types";


import { ScreenRoutesContext, ScreenRoutesContextProvider } from "Context/screenAccess-Provider_3"

dotenv.config();


const useStyles = makeStyles((theme) => ({
  // Link a style sheet with a function component using the hook pattern
  // root,margin, withoutLabel and textField classes defined here
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  loginPage: {
    // backgroundColor: "blue",
    marginLeft: "30vw",
    marginTop: "15vh",
    width: "40vw",
    display: "flex",
    flexDirection: "column",
    height: "30vh",
    justifyContent: "space-around",
    alignItems: "center",
  },
}));



const AuthMain = () => {
  const [valuesState, setValuesState] = useState({
    password: "",
    email: "",
    showPassword: false,
  });

  const [newSignInValuesState, setNewSignInValuesState] = useState({
    password: "",
    email: "",
    showPassword: false,
  });

  const [userState, setUserState] = useState(null);

  const [userAdminFlag, setUserAdminFlag] = useState(false);

  const [loginButtState, setLoginButtState] = useState(false);

  const routeContext = useContext(ScreenRoutesContext)

  //Listen for auth status changes
  firebase.auth().onAuthStateChanged((user) => {
    console.log("*****  on Auth State Changed--> ");
    console.log("--------  user ------");
    console.log("--> ", user, " <--");

    if (user !== undefined && user !== null) {
      console.log("--------  user NOT null ------");

      console.log("CHECK IF AN ADMIN USER !!  user.uid ==> ", user.uid);
      firebase
        .database()
        .ref(`users/${user.uid}`)
        .once("value")
        .then(function(snapshot) {
          let isAdmin;
          let isAdminWriteAccess;
          snapshot.child("admin").val() === true
            ? (isAdmin = true)
            : (isAdmin = false);
          setUserAdminFlag(isAdmin);
          snapshot.child("adminWriteAccess").val() === true
            ? (isAdminWriteAccess = true)
            : (isAdminWriteAccess = false);
          routeContext.setWriteAccess(isAdminWriteAccess)
        })
        .catch((error) => {
          console.log("admin user check error ==> ", error);
        });
    } else {
      user = null;
      setLoginButtState(false);
    }

    setUserState(user);
  });

  const getEmailPassVal = (theValues) => {
    console.log("getEmailPassVal  -- from login --> ");
    console.table(getEmailPassVal);
    setValuesState({ password: theValues.password, email: theValues.email });
  };



  useEffect(() => {
    // check if admin state when componentMount
    // if so enable login button - Note Login button will aslo need to check, not just a state flag!

    if (valuesState.email !== "" && valuesState.password !== "") {
      console.log("valuesState login before firebase auth --> ", valuesState);

      // Attempt to log in
      firebaseLogin(valuesState.email, valuesState.password, (err) => {
        console.log(
          `ERROR loging in -->  code ${err.code}      message ${err.message}`
        );
      });

      console.log("NEW SIGN IN completed ==> ");
    }

    if (newSignInValuesState.email !== "") {
      console.log("newSignInValuesState before firebase auth --> ");
      console.table(newSignInValuesState);

      // Attempt to create an account
      firebaseCreateAdminAccount(
        newSignInValuesState.name,
        newSignInValuesState.email,
        newSignInValuesState.password,
        (err) => {
          console.log("error creating new sign in --> ", err);
        }
      );
    }
    console.log("newSignInValuesState --> ", newSignInValuesState);
    //as state changed enable ???
  });

  // NOTE: see src/components/Navbars/AdminNavbarLinks.js for profile and logout

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.

  const loginButhandler = () => {
    console.log("login butt clicked");
    setLoginButtState(true);
  };

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          // adminState ? (
          userState !== null ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  const LoginPage = () => {
    console.log("LOGIN PAGE --> User state -->");
    console.table(userState);

    let history = useHistory();
    return (
      <div>
        <Button onClick={loginButhandler} className={{ color: "green" }}>
          Login
        </Button>
        {userAdminFlag === true && loginButtState === true
          ? history.push("/admin/dashboard")
          : null}
      </div>
    );
  };

  const classes = useStyles();

  //Need to check if admin user before allowing Private

  return (
    // <div className={classes.loginPage}>
    <div>

      {/* <ScreenRoutesContextProvider> */}

      <Router history={createBrowserHistory()}>
        {/* {adminState ? null : ( */}
        {userState !== null ? null : (
          <EmailPassLoginModal emailPasswordValuesCallback={getEmailPassVal} />
        )}
        <Switch>
          <Route path="/login" component={LoginPage} />
          <PrivateRoute path="/admin">
              <Route path="/admin" component={Admin} />
          </PrivateRoute>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>

      {/* </ScreenRoutesContextProvider> */}

    </div>
  );
};

ReactDOM.render(<ScreenRoutesContextProvider routes={allRoutes}><AuthMain /></ScreenRoutesContextProvider>, document.getElementById("root"));
