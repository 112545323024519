import React, { Component, forwardRef } from "react";
// core components
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBox from '@material-ui/icons/AddBox';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";


// database
import firebase from "../../firebase";


import LogModal from "../../components/DialogModal/LogModal";
import LogModalCartridgeInstalled from "../../components/DialogModal/LogModalCartridgeInstalled";
import EditableDetailObjectModal from "../../components/DialogModal/EditableDetailObjectModal";
import TablePagination from "@material-ui/core/TablePagination";


import { CsvBuilder } from 'filefy'

/**** Add not required or even this modified one as we've created a new 'action' that just adds a row ****
const tableIcons = {
  // Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <Fab ref={ref} {...props} color="primary" aria-label="add"><AddIcon /></Fab>),
};
*/

/** 
props passed to this component:  
theDbRef  --> eg 'alchemists', 'cartridges/records' 
theTitle  --> e.g. 'Alchemist Table', 'Cartridge Table' OR if non set to ""
**/

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      blankTemplate: {},
      deleteRowsAllowed: false,
      addGeneralLogs: false
    };
  }


  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }

  async componentMountandUpdate() {
    this.setState({ loaded: false });

    let theColumnArray = [];
    let newList = [];
    let entriesArray;

    let refTemplateDbIs = "";

    // Figure out what template to get
    let refString = this.props.theDbRef.split("/");


      refTemplateDbIs =
        "blankTemplate/cartridges_blankTemplates/generic_blank_templates";

      await firebase
        .database()
        .ref(refTemplateDbIs)
        .once("value", (snapshot) => {
          this.setState({ blankTemplate: snapshot.val() });
        });
  

    console.log("BlankTemplate  ==> ");
    console.table(this.state.blankTemplate);

    if (this.props.theDbRef !== "cartridges/records" && this.props.theDbRef !== "productsMade") {
      // All tables except Cartridges/records and ProductsMade are read stright in
      await firebase
        .database()
        .ref(this.props.theDbRef)
        .once("value", (snapshot) => {
          entriesArray = Object.entries(snapshot.val());
        });
    } else if (this.props.theDbRef === "cartridges/records") {
      /* 
        Must be viewing the  cartridge/records hence need to flatten the object, ie not display cartridge object as an object 
        
        Example:

        ----------------
        '-MABCD123' : {
                        Available:10
                        Inserted or used:7
                        Cartridge Batch Id: "PHXXXX"
                        cartridgeData:{
                                    Cartridge Batch Id: "PHXXXX"
                                    etc: ....
                                    etc:...
                                    .
                                    .

                        },
                        General Logs:{ {.....},{.....}}
        }
        -------------

        'cartridges key removed and this should look like

         ----------------
        '-MABCD123' : {
                        Available:10
                        Inserted or used:7
                        Cartridge Batch Id: "PHXXXX"
                        etc: ....
                        etc:...
                        .
                        .
                        General Logs:{ {.....},{.....}}
        }
        -------------

        */

      console.log(
        "**** FLATTENING the Cartridge Objects --> COMPONENT MOUNT !"
      );

      await firebase
        .database()
        .ref(this.props.theDbRef)
        .once("value", (snapshot) => {
          entriesArray = Object.entries(snapshot.val());
        });

      let flattEntries = [];
      entriesArray.forEach((entry, indexCount) => {
        let newObject = {};
        if (entry[1]["General Logs"] !== undefined) {
          newObject["General Logs"] = entry[1]["General Logs"];
        } else if (entry[1]["General Logs"] === undefined) {
          newObject["General Logs"] = {};
        }
        newObject["Inserted or used"] = entry[1]["Inserted or used"];

        newObject["Cartridge Batch Id"] = entry[1]["Cartridge Batch Id"];

        newObject.Available = entry[1].Available;

        let cartDataStuff = Object.entries(entry[1].cartridgeData);
        cartDataStuff.forEach((data) => {
          newObject[data[0]] = data[1];
        });

        flattEntries = [...flattEntries, [entry[0], newObject]];
      });

      console.log("*******  flattEntries   ===> ");
      console.table(flattEntries);
      entriesArray = flattEntries;
    }


    // first row = [0], value [1]  gives the column heading:data objects for the first row.
    //Then find the keys for this to get the list of headings

    console.log("**************  entriesArray start ==>  *********************");
    console.table(entriesArray);
    console.log("**************  <=== end of entriesArray *********************");

    let lastEntry = entriesArray.length - 1;
    let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);

    const buildColObject = (theArrayOfHeadings) => {
      let anArrayForColumns = [];

      theArrayOfHeadings.forEach((theHeading, i) => {
        let thisCol = {
          title: theHeading,
          field: camelize(theHeading),
          searchable: true,
          hidden:
            // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
            // /log|Log/.test(theHeading) === false
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // remember--> isAnObject === true if an object OR an array!
          isAnObject:
            (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
              ? true
              : false,
          // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
          isALog: /log|Log/.test(theHeading) ? true : false,
          type: /date|Date/.test(theHeading)
            ? "date"
            : /timestamp|Timestamp/.test(theHeading)
              ? "datetime"
              : /time|Time|created/.test(theHeading)
                ? "datetime"
                : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                  ? "numeric"
                  : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                    ? "string"
                    : typeof entriesArray[lastEntry][1][theHeading],
        };

        if (thisCol.isAnObject) {
          thisCol.editComponent = (props) => (
            <textarea
              type="text"
              multiline={true}
              rows={10}
              value={props.value}
              onChange={(e) => {
                if (JSON.parse(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          );
        }

        anArrayForColumns = [...anArrayForColumns, thisCol];
      });

      // tag ons
      anArrayForColumns = [
        {
          title: "Selected",
          field: "selected",
          type: "boolean",
          hidden: false,
          isAnObject: false,
          searchable: true,
        },

        ...anArrayForColumns,

        {
          title: "Unique Db Id",
          field: "uniqueDbId",
          type: "string",
          hidden: true,
          isAnObject: false,
          searchable: true,
        }
      ];

      return anArrayForColumns;
    };

    theColumnArray = buildColObject(arrayOfColHeadings);

    let newRowData = [];

    entriesArray.forEach((childEntry, count) => {
      let parentID = childEntry[0];

      let dataObjectForThisRow = childEntry[1];

      theColumnArray.forEach((heading) => {
        if (heading.title === "Unique Db Id") {
          newRowData.push(parentID);
        } else if (heading.title === "Selected") {
          newRowData.push(false);
        } else if (!heading.hidden) {
          newRowData.push(dataObjectForThisRow[heading.title]);
        } else if (heading.isAnObject) {
          newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
        }
      });

      newList = [...newList, newRowData];
      newRowData = []; // clear
    });

    let curState = this.state;
    curState.colArray = theColumnArray;
    curState.tableData = newList;
    curState.loaded = true;
    this.setState(curState);
  }

  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
componentDidMount() {
    this.componentMountandUpdate()
  }

  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }

  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {

      const listStyle = {
        padding:"1ch 5vw"
      }


      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li style={listStyle}>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };

    const renderTheDetails = (rowData) => {
      /* NOTE  -->
         rowData.tableData.id   -->  gives the row index  
         can use a field to access e.g. 'customerId --->   rowData.customerId   = "C12234"
      */

      const paperStyles = {
        padding: "2vh",
        textAlign: "center",
        color: "red",
        backgroundColor: "blue",
        // width: "30%",
      };



      const EditDetailObjButt = (props) => {

        const updateCallback = () => {

          console.log("++++++  EditableDetailObjectModal update screen ++++++")

          this.setState((prevState) => ({
            updateFirebaseDb: !prevState.updateFirebaseDb,
          }))

        }

        const editableDetailsObjectsSet = new Set()
        editableDetailsObjectsSet.add("Address")
        editableDetailsObjectsSet.add("Address Data")

        console.log("  EditDetailObjButt props == ", props.title)
        console.log("editableDetailsObjectsSet.has(title) --> ", editableDetailsObjectsSet.has(props.title))

        let detailedRef = (this.props.theDbRef !== "cartridges/records") ? (`${this.props.theDbRef}/${rowData.uniqueDbId}/${props.title}`) : (`${this.props.theDbRef}/${rowData.uniqueDbId}/cartridgeData/${props.title}`)

        return ( editableDetailsObjectsSet.has(props.title) ?
          <div
          >
            <EditableDetailObjectModal
          theTitle={props.title}
              theDbRef={detailedRef}
              toUpdate={updateCallback}
        />
          </div>
          : null
        )
      }


      const renderDetailsCardOfAnObject = (theTitle, elementObject, index) => {


        console.log("theTitle  --> ", theTitle);
        console.log("elementObject  --> ", elementObject);
        console.log("index  --> ", index);
        console.log("=================================");

        return (
          <div>
            <Card key={`${theTitle}__${index}`}>
              <Paper className={paperStyles.paper}>
                {elementObject === null ? null : (
                  <RenderSingleObject elementObject={elementObject} />
                )}
              </Paper>
            </Card>
          </div>
        );
      };

      const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
        return theCardArrayRow.map((element, index) => {
          return (
            <div
              key={`row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`}
            >
              {element !== null ? (
                <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
              ) : null}
              {typeof element === "object" && typeof element !== "isALog"
                ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
                // They need to be rendered differently as map() only works for an array
                renderDetailsCardOfAnObject(theTitle, element, index)
                : element}
            </div>
          );
        });
      };

      const renderTheCardDetails = (detailCard, detailCardIndex) => {
        if (rowData[this.state.colArray[detailCardIndex].field] === undefined) {
          /******   undefined object aka dosnt exist */
          return (
            <div>
              <Card
                key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
              >
                <CardHeader style={{ backgroundColor: "#888" }}>
                  <h5>{this.state.colArray[detailCardIndex].title}</h5>
                </CardHeader>
                <CardBody>
                  <Paper className={paperStyles.paper}>
                    <div>
                      <h6>Unknown</h6>
                    </div>
                  </Paper>
                </CardBody>
              </Card>
            </div>
          )
        }
        else
        return (
          <div>
            <Card
              key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
            >
              <CardHeader color="rose">
                <h5>{this.state.colArray[detailCardIndex].title}</h5>
                <EditDetailObjButt title={this.state.colArray[detailCardIndex].title} />
              </CardHeader>
              <CardBody>
                <Paper className={paperStyles.paper}>
                  {console.log(
                    "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
                  )}
                  {Array.isArray(
                    JSON.parse(
                      rowData[this.state.colArray[detailCardIndex].field]
                    )
                  ) === true
                    ? outputCardFromArray(
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex,
                      this.state.colArray[detailCardIndex].title
                    )
                    : renderDetailsCardOfAnObject(
                      this.state.colArray[detailCardIndex].title,
                      JSON.parse(
                        rowData[this.state.colArray[detailCardIndex].field]
                      ),
                      detailCardIndex
                    )}
                </Paper>
              </CardBody>
            </Card>
          </div>
        );
      };

      const getSpecicTitle = (rowData) => {
        let specificTitleInfo = ""

        if (/cartridges/.test(this.props.theDbRef)) {
          specificTitleInfo = `----- Device:${rowData.cartridgeBatchId}`
        }

        return specificTitleInfo
      }

      return (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            color: "black",
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <div>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              p={1}
              justifyContent="space-around"
            >
              {this.state.colArray.map((detailCard, detailCardIndex) => {
                if (detailCard.isAnObject && detailCard.isALog === false) {
                  {
                    return renderTheCardDetails(detailCard, detailCardIndex);
                  }
                } else if (detailCard.isALog === true) {
                  return (
                   (detailCard.title === "Cartridge Installation Log")
                      ?
                      <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                      >
                        {console.log("  SPECIFIC LOG  --- Cartridge Installation Log  ")}
                      <LogModalCartridgeInstalled
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                      </div>
                      :
                      <div
                      className={
                        ({ margin: "50px" },
                          { maxWidth: "10vw" },
                          { height: "80px" })
                      }
                      >
                      <LogModal
                        theTitle={detailCard.title}
                        theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // ' this.props.theDbRef '  was originaly  'fooBar/records'
                        detailCard={detailCard}
                        rowData={rowData}
                        detailCardIndex={detailCardIndex}
                        specificTitleInfo={getSpecicTitle(rowData)}
                      />
                    </div>
                  )
                

                }
              })}
            </Box>
          </div>
        </div>
      );
    };

    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          

          // return <div>TESTING render onClick</div>;
        },
      },
      {
        tooltip: "Allow rows to be deleted",
        icon: () => <DeleteIcon />,
        isFreeAction: true,
        onClick: (evt, data) => {
          console.log("allow delete rows");
          this.setState((prevState) => ({
            deleteRowsAllowed: !prevState.deleteRowsAllowed,
          }));
        },
      },
      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
      {
        // This replaces the  ` <MaterialTable --->  editable={{  --> onRowAdd: ` etc.  its code put in here
        tooltip: <div>Click to Add a Cartridge<br /><br />Note:<br />Please then edit that new<br />cartritdge row after adding it<br />by clicking<span>&nbsp;</span><span>&#10095;&#10072;</span><br />It will be the last one<br />Then click on the edit icon<span>&nbsp;&#9998;</span></div>,
        icon: () => <AddBox />,
        isFreeAction: true,
        onClick: (evt, data) => {
          console.log("Adding another cartridge")

              new Promise((resolve, reject) => {

                console.log(
                  "this.state.blankTemplate  --> ",
                  this.state.blankTemplate
                );

                firebase
                  .database()
                  .ref(this.props.theDbRef)
                  .push(this.state.blankTemplate, (error) => {
                    if (error) {
                      alert(
                        "Sorry, had a problem adding row to the database. Check if online"
                      );
                    } else {
                      resolve(
                        this.setState((prevState) => ({
                          updateFirebaseDb: !prevState.updateFirebaseDb,
                        }))
                      );
                    }
                  });
              })
          
        },
      },
    ];

    

    // if (this.props.theDbRef !== "users") {
    //   theActionsArray = [
    //     ...theActionsArray,
    //     {
    //       tooltip: "show -Add General Logs- button",
    //       icon: () => <NoteAddIcon />,
    //       isFreeAction: true,
    //       onClick: (evt, data) => {
    //         console.log("show -Add General Logs- button");
    //         this.setState((prevState) => ({
    //           addGeneralLogs: !prevState.addGeneralLogs,
    //         }));
    //       },
    //     },
    //   ];
    // }

    // if (this.state.addGeneralLogs === true && this.props.theDbRef !== "users") {
    //   theActionsArray = [
    //     ...theActionsArray,
    //     {
    //       tooltip: "Create a General Logs",
    //       icon: () => <NoteAddIcon />,
    //       onClick: (evt, data) => {
    //         console.log("Add a General log");

    //         const genLog = {
    //           Flags: "TBD",
    //           Notes: "This is  where notes go",
    //           timestamp: 23299943,
    //         };

    //         const createGenLog = () => {
    //           firebase.database().ref(
    //             this.props.theDbRef
    //               .child(data["uniqueDbId"])
    //               .child("General Logs")
    //               .push(genLog)
    //           );
    //         };

    //         data["generalLogs"] === {}
    //           ? createGenLog()
    //           : console.log("already have a general log");
    //         console.log("this.props.theDbRef", this.props.theDbRef);
    //         console.log("*** data['uniqueDbId'] ==> ", data["uniqueDbId"]);
    //         console.log("data.tableData.id  ==>", data.tableData.id);
    //       },
    //     },
    //   ];
    // }



    const renderTheTable = () => {
      return (
        <div>
          {/*this.state.showModal ? (
            <LogModal
            // theTitle="Cartridge Log TEST!!"
            // theDbRef="cartridges/"
            />
          ) : null*/}

          <MaterialTable
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            // icons={tableIcons}
            detailPanel={[
              {
                tooltip: "Show Extra Details",
                render: renderTheDetails,
              },
            ]}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 50, 500]}
                />
              ),
            }}
            options={{
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "500px",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              // selection: true,
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              // exportCsv: (columns, data) => {
              //   alert("Own export" + data.length + " rows");
              // },
            }}

            actions={theActionsArray}
            editable={{
              // onRowAdd: REMOVED! Created a new `action` so can have better tooltip and create a row in one click
              
              onRowUpdate:
                this.props.theDbRef !== "cartridges/records"
                  ? (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log("NORMAL SCREEEN UPDATE -- WORKS!!");

                      console.log("oldData ===>> ", oldData);

                      console.log("newData ===>> ", newData);

                      console.log("id ==  ", oldData.tableData.id);

                      console.log("this.state.tableData == > >");
                      console.table(this.state.tableData);

                      let newRowBuild = {};

                      let theData = Object.entries(newData);

                      theData.map((element, theIndex) => {
                        console.log("element --> ", element);
                        console.log("fieldName --> ", element[0]);
                        console.log("theIndex  --> ", theIndex);

                        //let fieldName = element[0];
                        let dataIs = element[1];
                        let titleName = this.state.colArray[theIndex].title;

                        if (
                          /Date/.test(titleName) ||
                          /date/.test(titleName)
                        ) {
                          //   need to check if date/time to parse to a timestamp
                          console.log("date --> Date.parse(dataIs)");
                          console.log(Date.parse(dataIs));
                          dataIs = Date.parse(dataIs);
                        } else if (
                          /time/.test(titleName) ||
                          /Time/.test(titleName) ||
                          /timestamp/.test(titleName) ||
                          /Timestamp/.test(titleName)
                        ) {
                          //   need to check if time to parse to a timestamp
                          //dataIs = new Date(dataIs).getTime();
                          dataIs = Date.parse(dataIs);
                        } else if (
                          this.state.colArray[theIndex].type === "numeric"
                        ) {
                          dataIs = parseFloat(dataIs);
                        } else if (/\{/.test(dataIs)) {
                          // check if its an object
                          dataIs = JSON.parse(dataIs);
                        }

                        newRowBuild[titleName] = dataIs;
                      });

                      console.log("  newRowBuild ==>");
                      console.table(newRowBuild);

                      const oldDataWithUniqueDbIdAndGroupColDeleted = (
                        theOldDataObject
                      ) => {
                        let newDataObj = { ...theOldDataObject };
                        delete newDataObj.tableData;
                        delete newDataObj["Unique Db Id"];
                        delete newDataObj["Selected"];
                        delete newDataObj.group;
                        return newDataObj;
                      };

                      let newUpdated = oldDataWithUniqueDbIdAndGroupColDeleted(
                        newRowBuild
                      );

                      console.log(
                        "and what is being pushed to firebase is -->"
                      );
                      console.table(newUpdated);

                      console.log(
                        "old Data row ==>  this.state.tableData[oldData.tableData.id] --> ",
                        this.state.tableData[oldData.tableData.id]
                      );

                      firebase
                        .database()
                        .ref(this.props.theDbRef)
                        .child(oldData.uniqueDbId)
                        .set(newUpdated, (error) => {
                          if (error) {
                            reject(
                              alert(
                                "Sorry, had a problem updating to the database. Check if online"
                              )
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });
                    })
                  : (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(
                        "*****    UPDATE ROW  FOR CARTRIDGES  *******"
                      );
                      console.log(
                        "this.state.updateFirebaseDb",
                        this.state.updateFirebaseDb
                      );

                      let newRowBuild = {};

                      let theData = Object.entries(newData);

                      console.log("Cartridge --> update Data entries array");
                      console.table(theData);

                      theData.map((element, theIndex) => {
                        //let fieldName = element[0];
                        let dataIs = element[1];
                        let titleName = this.state.colArray[theIndex].title;

                        if (
                          /Date/.test(titleName) ||
                          /date/.test(titleName)
                        ) {
                          //   need to check if date/time to parse to a timestamp
                          console.log(
                            `date --> Date.parse(dataIs) ${Date.parse(
                              dataIs
                            )}   on index number ${theIndex}`
                          );
                          dataIs = Date.parse(dataIs);
                        } else if (
                          /time/.test(titleName) ||
                          /Time/.test(titleName) ||
                          /timestamp/.test(titleName) ||
                          /Timestamp/.test(titleName)
                        ) {
                          //   need to check if time to parse to a timestamp
                          console.log("timestamp --> dataIs");
                          dataIs = new Date(dataIs).getTime();
                        } else if (
                          this.state.colArray[theIndex].type === "numeric"
                        ) {
                          dataIs = parseFloat(dataIs);
                        } else if (/\{/.test(dataIs)) {
                          // check if its an object
                          dataIs = JSON.parse(dataIs);
                        }

                        newRowBuild[titleName] = dataIs;
                      });

                      console.log(
                        "Cartridge Update \n====== newRowBuild ==="
                      );
                      console.table(newRowBuild);

                      let revisedNewRowObject = {};

                      revisedNewRowObject["Available"] =
                        newRowBuild["Available"];
                      
                      revisedNewRowObject["Inserted or used"] =
                        newRowBuild["Inserted or used"];
                      
                        revisedNewRowObject["Cartridge Batch Id"] =
                        newRowBuild["Cartridge Batch Id"];
                      

                      if (newRowBuild["General Logs"] !== undefined) {
                        revisedNewRowObject["General Logs"] =
                          newRowBuild["General Logs"];
                      }

                      delete newRowBuild["Available"];
                      delete newRowBuild["Inserted or used"];
                      delete newRowBuild["General Logs"];
                      delete newRowBuild.tableData;
                      delete newRowBuild["Unique Db Id"];
                      delete newRowBuild["Selected"];
                      delete newRowBuild.group;

                      let cartridgeData = { ...newRowBuild };

                      revisedNewRowObject["cartridgeData"] = cartridgeData;
                      console.log("*** cartridgeData ==>");
                      console.table(cartridgeData);

                      console.log(
                        "*****   UPDATE OUTPUT  revisedNewRowObject ==>"
                      );
                      console.table(revisedNewRowObject);

                      firebase
                        .database()
                        .ref(this.props.theDbRef)
                        .child(oldData.uniqueDbId)
                        .set(revisedNewRowObject, (error) => {
                          if (error) {
                            reject(
                              alert(
                                "Sorry, had a problem updating to the database. Check if online"
                              )
                            );
                          } else {
                            resolve(
                              this.setState((prevState) => ({
                                updateFirebaseDb: !prevState.updateFirebaseDb,
                              }))
                            );
                          }
                        });
                    }),
              onRowDelete: this.state.deleteRowsAllowed
                ? (oldData) =>
                  new Promise((resolve, reject) => {
                    console.log("*****    DELETE DELETE  *******");

                    console.log("-->  oldData --> ", oldData);

                    console.log(
                      `this.props.theDbRef/oldData.uniqueDbId    ${this.props.theDbRef}/${oldData.uniqueDbId}`
                    );

                    console.log("===============");

                    let removedRowTableData = [...this.state.tableData];

                    const i = removedRowTableData.indexOf(
                      oldData.tableData.id
                    );

                    removedRowTableData.splice(i, 1);

                    this.setState({
                      tableData: removedRowTableData,
                    });

                    firebase
                      .database()
                      .ref(this.props.theDbRef + "/" + oldData.uniqueDbId)
                      .remove((error) => {
                        if (error) {
                          alert(
                            "Sorry, had a problem updating to the database. Check if online"
                          );
                        } else {
                          resolve(
                            this.setState((prevState) => ({
                              updateFirebaseDb: !prevState.updateFirebaseDb,
                            }))
                          );
                        }
                      });
                  })
                : null,
            }}
          />
        </div>
      );
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabase;
