import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "45ch",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const NewSignInModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    password: "",
    email: "",
    name: "",
    showPassword: false,
    retailId: "",
    adminStatus: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
    setValues({
      password: "",
      email: "",
      name: "",
      showPassword: false,
      retailId: "",
      adminStatus: false,
    });
  };

  const handleSubmittAndClose = () => {
    props.newSignInEmailPasswordValuesCallback(values);
    props.toSubmittCallback(true);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEmail = (event) => {
    setValues({ ...values, email: event.target.value });
    console.log("email is -->values.email ", values.email);
  };

  const handleChangeName = (event) => {
    setValues({ ...values, name: event.target.value });
    console.log("name is -->values.name ", values.name);
  };

  const handleChangePassword = (event) => {
    setValues({ ...values, password: event.target.value });
    console.log("password -->values.password ", values.password);
  };

  const handleChangeRetailId = (event) => {
    setValues({ ...values, retailId: event.target.value });
    console.log("retailId -->values.retailId ", values.retailId);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckbox = () => {
    setValues({ ...values, adminStatus: !values.adminStatus });
  };

  return (
    <div>
      <Button
        className={{ margin: "50px" }}
        color="secondary"
        style={{ backgroundColor: "grey" }}
        variant="contained"
        onClick={handleClickOpen}
      >
        New User
      </Button>
      <Dialog
        fullWidth={"ml"}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {values.adminStatus ? (
          <DialogTitle id="max-width-dialog-title">
            *** New ADMIN User ***
          </DialogTitle>
        ) : (
          <DialogTitle id="max-width-dialog-title">New Retail User</DialogTitle>
        )}

        <DialogContent>
          {/* <DialogContentText>REMINDER: ADMIN ACCOUNT !</DialogContentText> */}
          <div>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              required="true"
            >
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type={"email"}
                onChange={handleChangeEmail}
                labelWidth={70}
                value={values.email}
              />
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              required="true"
            >
              <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-name"
                type={"text"}
                onChange={handleChangeName}
                labelWidth={70}
                value={values.name}
              />
            </FormControl>

            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              required="true"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password (min 6 characters)
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChangePassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            {values.admin ? null : (
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                required="true"
              >
                <InputLabel htmlFor="outlined-adornment-name">
                  Retailer Id (eg. Marionnaud)
                </InputLabel>

                <OutlinedInput
                  id="outlined-adornment-name"
                  type={"text"}
                  onChange={handleChangeRetailId}
                  labelWidth={70}
                  value={values.retailId}
                  disabled={values.admin}
                />
              </FormControl>
            )}
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              required="true"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.adminStatus}
                    onChange={handleCheckbox}
                    name="Admin"
                  />
                }
                label="Admin"
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleSubmittAndClose} color="secondary">
            Submitt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewSignInModal;
