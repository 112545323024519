import firebase from "../firebase.js";

const alchemistGeneralLogBlankTemplateDbRef = firebase
  .database()
  .ref()
  .child("alchemists/blankTemplate/General Logs");

const alchGenLogBlankTemplate = () => {
  let genLog = {
    timestamp: 11577600,
    Flags: "TBD",
    notes: "notes for customer interaction for this product post purchase",
  };

  alchemistGeneralLogBlankTemplateDbRef.set({});
  alchemistGeneralLogBlankTemplateDbRef.push(genLog);
};

export { alchGenLogBlankTemplate };
