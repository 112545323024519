
    /************   INDICATE THE SPEED OF USE    **********
     * 
     *  for each device 
     *          Pull in entire 'Cartridge Install Log'
     * -------
     * -MmNkLlBhcckHkTWdbkM
     *      Current Weight (g): 110.26799999999997
     *      Density: 1.158
     *      Expiry Date: 1672531200000
     *      Warning Weight: 4
     *      Weight: 111.168
     *      batch_id: "PARA22012021"
     *      batch_id: "-MTgMXfRIrP8vwrWuNpY"
     * -------
     *      
     * 
     * Use 'batch_id' to find 'Cartridge Name'
     * 
     * --------
     * cartridges/count/records/batch_id/cartridgeData/Cartridge Name
     * ---------
     * 
     * 
     * 
     *  "cartPerPumpTally" : {
     * 
     *     "device-foo" : {
     * 
     *                      "Gloss(Radiance)" : 1,
     *                      "Preserved Water" : 0,
     *                      "White Pigment" : 0,
     *                      "Black Pigment" : 1,
     *                      "Yellow Pigment" : 2,
     *                      "Red Pigment" : 0,
     *                      "Matte" : 2,
     *                      "Glycerin (Moisturiser)" : 0,
     *                      "Sheer" : 2,     // same as  "Unpigmented/sheer"
     *          }
     *          .
     *          .
     *          .
     * 
     * 
     *      }
     * 
     *          
     * 

     * 
     * ******************  BAR CHART   ***************
     * 
     * 
     * Can construct array (as per code samples below for cartist.js or google-charts ) to display the data as a Bar chart total tally
     * 
     * 
     * ---------
     * .
     * .
     *         data={[
     *                  ['Cartridge Name', 'Red', 'Yellow', 'White', 'Black', 'Water', 'Matte', 'Sheer', 'Gloss', 'Glycerin'],
     *                  ['Total', 20, 9, 11, 3, 4, 7, 2, 11, 9],
     *               ]}
     * .
     * .
     * ----------
     * 
     * Functionality  -->
     *      Select Cartridge Name(s); Select Device(s) (eg devices: device-EFG, device-mark    Pumps: 'Red' , 'white')
     * 
     * 
     * https://gionkunz.github.io/chartist-js/
     * https://github.com/fraserxu/react-chartist
     * https://demos.creative-tim.com/material-dashboard-pro-react/#/documentation/charts
     * 
     * 
     * https://react-google-charts.com/bar-chart#material-design
     * 
     *******************************************************/




import React, { Component, useMemo } from "react";
// core components
// import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";

//icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// database
import firebase from "../../firebase";
// import Button from "@material-ui/core/Button";

// import LogModal from "../../components/DialogModal/LogModal";
import TablePagination from "@material-ui/core/TablePagination";

import { CsvBuilder } from 'filefy'

// To camelcase the headings to use as field names; also strips off everything from the first open bracket onwards
const camelize = (str) => {
  return str
    .split("(")[0]
    .toString()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

class TableDatabaseCountBetweenDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      colArray: [],
      loaded: false,
      updateFirebaseDb: false,
      cartridgeTypeTally: {},
      batchIdTally: {},
      batchKeyTally: {},
    };
  }



  getNewSignInEmailPassVal(theValues) {
    console.log("getNewSignInEmailPassVal --> ", theValues);
    
  }


  async componentMountandUpdate() {
  this.setState({ loaded: false });


  let theColumnArray, newList = []
  let entriesArray, getEntries, entriesCartArray, getCartEntries, getAlchEntries

 if (this.props.theDbRef === "CartridgeTally") {

   console.log("******* CARTRIDGE TALLY SCREEN ******* ")
   
   const countCarts = (theInstallLog, deviceName) => {
     console.log(`deviceName --> ${deviceName}`)
     console.log(`theInstallLog -->`)
     console.log(theInstallLog)
     
    let logcount = 0
     let batchIdLog = []
     let batchKeyLog = []
     
     for (const cartInstall in theInstallLog) {
       
      if (cartInstall !== '---' || cartInstall !== '----') {

        logcount++

        let batch_id = theInstallLog[cartInstall]['batch_id']
        let batch_key = theInstallLog[cartInstall]['batch_key']

        if (batch_id !== undefined) {
          batchIdLog.push(batch_id)
        }
        if (batch_key !== undefined) {
          batchKeyLog.push(batch_key)
        }

      }
       
     }

     
     console.log("---------------------------------")
     console.log(`log count for ${deviceName} is ==>  ${logcount}`)
     console.log("---  batchIdLog  ---", batchIdLog)
     console.log("---  batchKeyLog  ---", batchKeyLog)
     
    this.setState(prevState => { batchIdTally: this.state.batchIdTally[deviceName] = [...batchIdLog] });
    this.setState(prevState => { batchKeyTally: this.state.batchKeyTally[deviceName] = [...batchKeyLog] });

   }
   



   /**** Store in state the batch_id and batch_keys for each alchemist */
   try {
        
          let theFirebaseAlchRef = firebase.database().ref("alchemists/records")
          let getAlchSnapshot = await theFirebaseAlchRef.once("value")
          let valueAlch = await getAlchSnapshot.val()

          if (getAlchEntries !== undefined || getAlchEntries !== null) {
            getAlchEntries = Object.entries(valueAlch)

          console.log(" +++++ get an array of all alchemist devices +++++")
          console.table(getAlchEntries)

          getAlchEntries.forEach((device, index) => {
            console.log(`+++++++  LOOP  ${index} ++++++++`)
            let deviceName = device[1]['Device Id']
            let installLog = device[1]['Cartridge Install Log']
            let installLogOlderRefPoint = device[1]['Cartridge Installation Log']

              
            if (installLog !== undefined) {
              countCarts(installLog, deviceName)
            }
            
            else if (installLogOlderRefPoint !== undefined) {
              console.log("***   older log - no batch id! ***")
              countCarts(installLogOlderRefPoint, deviceName)
              
            }
              
            //NOTE: both Cartridge Install Log and Cartridge Installation log might both exist
            else if (installLog == undefined && installLogOlderRefPoint == undefined) {
              console.log(`POSSIBLY A NEWLY INSTALLED ALCHEMIST as Cartridge Install Log  --- for ${deviceName} is undefined `)
            }
          })
        
        }

     
      console.log("====== state ====")
     console.table(this.state)
     
      }
          
      catch(e) {
          console.log("Firebase alchemist/records call ERROR --> ", e)
      }


   
   
/**** Using the batch_ids and / or batch_keys find the cartridge types for each Alchemist and store in cartridgeTypeTally state  */

try {
        
  let theFirebaseCartridgesRef = firebase.database().ref("cartridges/records")
  let getCartridgesSnapshot = await theFirebaseCartridgesRef.once("value")
  let valueCart = await getCartridgesSnapshot.val()
  getCartEntries = Object.entries(valueCart)

   
console.log("====== state after find the cartridge types for each Alchemist and store in cartridgeTypeTally state ====")
console.table(this.state)

}
  
catch(e) {
  console.log("Firebase cartridges/records call ERROR --> ", e)
}

/**** Create Get a set of all used Cartridge Batch Ids and there corresponding Descriptions  *****/


let allCartsIdAndDescriptionArray = []

// for some reason need to spread otherwise get error
   entriesCartArray = [...getCartEntries]
   
   entriesCartArray.forEach((entry) => {
     allCartsIdAndDescriptionArray.push([ entry[1].cartridgeData['Cartridge Batch Id'] , entry[1].cartridgeData['Cartridge Name'] ])
      }
    )
   

   let setOfCartDescriptions = new Set()

   allCartsIdAndDescriptionArray.map(item => setOfCartDescriptions.add(item))

  
   
   let allCartDesriptionTallyNames = new Set()
   let cartIdVsDesriptionLookUpTable = {}

   const cleanUpDecription = (cartDescrition) => {
     let camit = camelize(cartDescrition)
     if (camit === "unpigmented/Sheer") { camit = "sheer" }
     return camit
   }
   
// Create a set to act as a lookup table for [catridge id, cart description]
   setOfCartDescriptions.forEach((cartIdAndDescritionElement, index) => {
     allCartDesriptionTallyNames.add(cleanUpDecription(cartIdAndDescritionElement[1]))
     cartIdVsDesriptionLookUpTable[cartIdAndDescritionElement[0]] = cleanUpDecription(cartIdAndDescritionElement[1])
    })

   /*** Using the look up table create a cartridgeTypeTally for for each device */

   let cartTallyObjectForDevice = {}
  
   for (const device in this.state.batchIdTally) {

     // Get desription from lookup table
     this.state.batchIdTally[device].forEach(cart => {
       let description = cartIdVsDesriptionLookUpTable[cart]
       if (cartTallyObjectForDevice[device] == undefined) { cartTallyObjectForDevice[device] = {} }
        cartTallyObjectForDevice[device][description] = cartTallyObjectForDevice[device][description] + 1 || 1
     })

   }

   this.setState({ cartridgeTypeTally : cartTallyObjectForDevice })

   /****  Arranged into an array so can be rendered using Material Table  */
   let cartTallyArray = []
   for (const device in this.state.cartridgeTypeTally) {
     this.setState(prevState => { cartridgeTypeTally: prevState.cartridgeTypeTally[device] = { device, ...prevState.cartridgeTypeTally[device] } } )
     cartTallyArray.push([device, this.state.cartridgeTypeTally[device]])
   }
   
   entriesArray = [...cartTallyArray]

  }


  // first row = [0], value [1]  gives the column heading:data objects for the first row.
  //Then find the keys for this to get the list of headings

  let lastEntry = entriesArray.length - 1;
  let arrayOfColHeadings = Object.keys(entriesArray[lastEntry][1]);

  const buildColObject = (theArrayOfHeadings) => {
    let anArrayForColumns = [];

    theArrayOfHeadings.forEach((theHeading, i) => {
      let thisCol = {
        title: theHeading,
        field: camelize(theHeading),
        searchable: true,
        hidden:
          // (typeof entriesArray[lastEntry = 1][1][theHeading]).toString() === "object" &&
          // /log|Log/.test(theHeading) === false
          (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
            ? true
            : false,
        // remember--> isAnObject === true if an object OR an array!
        isAnObject:
          (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
            ? true
            : false,
        // 'isALog' is not a material-table type; us it see show its a log. Detail this diffetently
        isALog: /log|Log/.test(theHeading) ? true : false,
        type: /date|Date/.test(theHeading)
          ? "date"
          : /timestamp|Timestamp/.test(theHeading)
            ? "datetime"
            : /time|Time|created/.test(theHeading)
              ? "datetime"
              : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "number"
                ? "numeric"
                : (typeof entriesArray[lastEntry][1][theHeading]).toString() === "object"
                  ? "string"
                  : typeof entriesArray[lastEntry][1][theHeading],
      };

      if (thisCol.isAnObject) {
        thisCol.editComponent = (props) => (
          <textarea
            type="text"
            multiline={true}
            rows={10}
            value={props.value}
            onChange={(e) => {
              if (JSON.parse(e.target.value)) {
                props.onChange(e.target.value);
              }
            }}
          />
        );
      }

      anArrayForColumns = [...anArrayForColumns, thisCol];
    });

    // tag ons
    anArrayForColumns = [
      {
        title: "Selected",
        field: "selected",
        type: "boolean",
        hidden: false,
        isAnObject: false,
        searchable: true,
      },

      ...anArrayForColumns,

      // {
      //   title: "Unique Db Id",
      //   field: "uniqueDbId",
      //   type: "string",
      //   hidden: true,
      //   isAnObject: false,
      //   searchable: true,
      // },
      {
        //   title: "group",
        //   field: "group",
        //   // defaultGroupOrder: 0,
        //   defaultGroupSort: "asc",
        //   hidden: true,
        //   isAnObject: false,
        // searchable: true,
      },
    ];

    return anArrayForColumns;
  };

  theColumnArray = buildColObject(arrayOfColHeadings);

  let newRowData = [];

  entriesArray.forEach((childEntry, count) => {
    let parentID = childEntry[0];

    let dataObjectForThisRow = childEntry[1];

    theColumnArray.forEach((heading) => {
      if (heading.title === "Unique Db Id") {
        newRowData.push(parentID);
      } else if (heading.title === "Selected") {
        newRowData.push(false);
      } else if (!heading.hidden) {
        newRowData.push(dataObjectForThisRow[heading.title]);
      } else if (heading.isAnObject) {
        newRowData.push(JSON.stringify(dataObjectForThisRow[heading.title]));
      }
      // else newRowData.push(dataObjectForThisRow[heading.title]);
    });

    newList = [...newList, newRowData];
    newRowData = []; // clear
  });

  let curState = this.state;
  curState.colArray = theColumnArray;
  curState.tableData = newList;
  curState.loaded = true;
  this.setState(curState);
}


  /*****************    COMPONENT DID MOUNT    *****************/
  /*************************************************************/
  componentDidMount() {
    this.componentMountandUpdate()
  }

  /*****************    COMPONENT DID UPDATE    *****************/
  /**************************************************************/
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateFirebaseDb !== prevState.updateFirebaseDb) {
      this.componentMountandUpdate()
    }
  }



  /*******************   RENDER    ******************/
  /**************************************************/
  render() {
    // create an array of data objects to be passed to the data prop
    let theData = [];

    this.state.tableData.forEach((row) => {
      let objectOfFieldData = {};
      this.state.colArray.forEach((col, colNumber) => {
        col.type === "date" || col.type === "datetime"
          ? (objectOfFieldData[col.field] = new Date(
            row[colNumber]
          ).toDateString())
          : (objectOfFieldData[col.field] = row[colNumber]);
      });

      theData = [...theData, objectOfFieldData];
    });

    const RenderSingleObject = (props) => {
      let elementObject = props.elementObject;
      let theEntries = Object.entries(elementObject);
      console.log("=== the Entries to object = ", theEntries);

      const theList = theEntries.map((element, indexInner) =>
        typeof element[1] !== "object" ? (
          <li>
            {element[0]} --{">"} {element[1]}
          </li>
        ) : (
          <div>
            <h6>{element[0]}</h6>
            <RenderSingleObject elementObject={element[1]} />
          </div>
        )
      );
      return <div>{theList}</div>;
    };

    // const renderTheDetails = (rowData) => {
    //   /* NOTE  -->
    //      rowData.tableData.id   -->  gives the row index  
    //      can use a field to access e.g. 'customerId --->   rowData.customerId   = "C12234"
    //   */

    //   const paperStyles = {
    //     padding: "2vh",
    //     textAlign: "center",
    //     color: "red",
    //     backgroundColor: "blue",
    //     // width: "30%",
    //   };

    //   const renderDetailsCardOfAnObject = (theTitle, elementObject, index) => {
    //     console.log("theTitle  --> ", theTitle);
    //     console.log("elementObject  --> ", elementObject);
    //     console.log("index  --> ", index);
    //     console.log("=================================");
    //     return (
    //       <div>
    //         <Card key={`${theTitle}__${index}`}>
    //           <Paper className={paperStyles.paper}>
    //             {elementObject === null ? null : (
    //               <RenderSingleObject elementObject={elementObject} />
    //             )}
    //           </Paper>
    //         </Card>
    //       </div>
    //     );
    //   };

    //   const outputCardFromArray = (theCardArrayRow, theIndex, theTitle) => {
    //     return theCardArrayRow.map((element, index) => {
    //       return (
    //         <div
    //           key={`row_${rowData.tableData.id}_detail_${theIndex}_element_${index}`}
    //         >
    //           {element !== null ? (
    //             <span>{/pump|Pump/.test(theTitle) ? `${index}  -->` : ``}</span>
    //           ) : null}
    //           {typeof element === "object" && typeof element !== "isALog"
    //             ? //Details found to be in object form eg. {Cartridge ID installed: 220005, Cartridge batch ID installed: "PASH-9", ...}
    //             // They need to be rendered differently as map() only works for an array
    //             renderDetailsCardOfAnObject(theTitle, element, index)
    //             : element}
    //         </div>
    //       );
    //     });
    //   };

    //   const renderTheCardDetails = (detailCard, detailCardIndex) => {
    //     return (
    //       <div>
    //         <Card
    //           key={`detail_${rowData.tableData.id}_detail_${detailCardIndex}`}
    //         >
    //           <CardHeader color="rose">
    //             <h5>{this.state.colArray[detailCardIndex].title}</h5>
    //           </CardHeader>
    //           <CardBody>
    //             <Paper className={paperStyles.paper}>
    //               {console.log(
    //                 "++++++ CHECK IF AN OBJECT OR ARRAY BEOFRE RENDERING"
    //               )}
    //               {Array.isArray(
    //                 JSON.parse(
    //                   rowData[this.state.colArray[detailCardIndex].field]
    //                 )
    //               ) === true
    //                 ? outputCardFromArray(
    //                   JSON.parse(
    //                     rowData[this.state.colArray[detailCardIndex].field]
    //                   ),
    //                   detailCardIndex,
    //                   this.state.colArray[detailCardIndex].title
    //                 )
    //                 : renderDetailsCardOfAnObject(
    //                   this.state.colArray[detailCardIndex].title,
    //                   JSON.parse(
    //                     rowData[this.state.colArray[detailCardIndex].field]
    //                   ),
    //                   detailCardIndex
    //                 )}
    //             </Paper>
    //           </CardBody>
    //         </Card>
    //       </div>
    //     );
    //   };

    //   const getSpecicTitle = (rowData) => {
    //     let specificTitleInfo = "";

    //     return specificTitleInfo;
    //   };

    //   return (
    //     <div
    //       style={{
    //         fontSize: 20,
    //         textAlign: "center",
    //         color: "black",
    //         backgroundColor: "inherit",
    //         maxWidth: "80vw",
    //       }}
    //     >
    //       <div>
    //         <Box
    //           display="flex"
    //           flexWrap="wrap"
    //           flexDirection="row"
    //           p={1}
    //           justifyContent="space-around"
    //         >
    //           {this.state.colArray.map((detailCard, detailCardIndex) => {
    //             if (detailCard.isAnObject && detailCard.isALog === false) {
    //               {
    //                 return renderTheCardDetails(detailCard, detailCardIndex);
    //               }
    //             } else if (detailCard.isALog === true) {
    //               return (
    //                 <div
    //                   className={
    //                     ({ margin: "50px" },
    //                       { maxWidth: "10vw" },
    //                       { height: "80px" })
    //                   }
    //                 >
    //                   <LogModal
    //                     theTitle={detailCard.title}
    //                     theDbRef={`${this.props.theDbRef}/${rowData.uniqueDbId}/${detailCard.title}`} // remember--> ' this.props.theDbRef '  was originaly  'fooBar/records'
    //                     detailCard={detailCard}
    //                     rowData={rowData}
    //                     detailCardIndex={detailCardIndex}
    //                     specificTitleInfo={getSpecicTitle(rowData)}
    //                   />
    //                 </div>
    //               );
    //             }
    //           })}
    //         </Box>
    //       </div>
    //     </div>
    //   );
    // };

    let theActionsArray = [
      {
        tooltip: "Export Selected",
        icon: "publish",
        isFreeAction: true,
        onClick: (evt, data) => {

          let dataToExport = this.state.tableData.filter((row) => {
            return row[0] === true;
          });


          let dataToExportNoSelect = dataToExport.map((row) => {
            row.shift();
            return row;
          });


          let fileName = prompt("Please enter file name");

          let jsonFile = JSON.stringify(dataToExportNoSelect).replace(/\\/g, "")

          console.log(`file name is ${fileName} and file content is -->\n ${jsonFile}`)

          console.log("*****************************\n");

          console.table(dataToExportNoSelect);

          
          let allHeadings = []
          this.state.colArray.forEach(element => {
            if (element['title'] !== "Selected" && element['title'] !== undefined) {
              allHeadings.push(element['title'])
            }
              }
          )

          const getHeadingFields = Object.values(allHeadings)
          
          const csvBuilder = new CsvBuilder(`${fileName}.csv`)
            
            .setColumns(getHeadingFields)
            .addRows(dataToExportNoSelect)
            .exportFile();
          
        },
      },

      {
        tooltip: "Select",
        icon: () => <CheckBoxOutlineBlankIcon />,
        onClick: (event, rowData) => {
          this.setState(
            (prevState) =>
            (prevState.tableData[rowData.tableData.id][0] = !prevState
              .tableData[rowData.tableData.id][0])
          );
        },
      },
      
    ];




    const renderTheTable = () => {
      return (
        <div>
          <MaterialTable
            style={{minHeight : "auto"}}
            title={this.props.theTitle}
            columns={this.state.colArray}
            data={theData}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              ),
            }}
            options={{
              pageSize:10,
              //emptyRowsWhenPaging: true,
              columnsButton: true,
              exportAllData: true,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "auto",
              sorting: true,
              grouping: true,
              // filtering: true,
              search: true,
              //selection: true,
              exportButton: false,
              rowStyle: (rowData) => ({
                backgroundColor: this.state.tableData[rowData.tableData.id][0]
                  ? "#666"
                  : "#FFF",
                color: this.state.tableData[rowData.tableData.id][0]
                  ? "white"
                  : "#111",
              }),

              exportCsv: (columns, data) => {
                alert("Own export" + data.length + " rows");
              },
            }}
            // onSelectionChange={(rows) =>
            //   console.log("You selected -->  rows ", rows)
            // }
            actions={theActionsArray}
          />
        </div>
      );
    }
    return (
       <div style={{ maxWidth: "100%" }}>
      {/* <div style={{ maxWidth: "100%" }, {height : "90vh"}}> */}
      {this.state.loaded ? renderTheTable() : null}
      </div>
    );
  }
}

export default TableDatabaseCountBetweenDates;
