import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import EditIcon from '@material-ui/icons/Edit';
import TableDatabaseForEditableDetailedObject from "../../views/TableDbComp/TableDatabaseForEditableDetailedObject";

const useStyles = makeStyles((theme) => ({
  // form: {
  //   display: "flex",
  //   flexDirection: "column",
  //   margin: "auto",
  //   width: "fit-content",
  // },
  // formControl: {
  //   marginTop: theme.spacing(2),
  //   minWidth: 120,
  // },
  // formControlLabel: {
  //   marginTop: theme.spacing(1),
  // },
}));




const EditableDetailObjectModal = (props) => {
  // DONT NEED ALL THESE PROPS !! -- JUSt FIGURING out how to get firebase node name
  const {
    theDbRef,
    theTitle,
    toUpdate,
    // titleIs,
    // specificTitleInfo,
    // detailCard,
    // rowData,
    // detailCardIndex,
  } = props;



  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    toUpdate() /* Always update on close */
  };

  return (
    <div>
      <Button
        // className={{ margin: "250px" }}
        // color="default"
        style={{backgroundColor:"inherit"}}
        variant="contained"
        onClick={handleClickOpen}
      >
        <EditIcon color="action" fontSize="small" />
      </Button>
      <Dialog
        fullWidth={"xl"}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          
          <TableDatabaseForEditableDetailedObject
            theDbRef={theDbRef}
            theTitle={`${theTitle}`}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditableDetailObjectModal;
